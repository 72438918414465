import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClient, TeamEditModel, UserModel, TeamCreateModel, NameIdModel } from 'src/app/services/api.client';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UserSelectDialog } from 'src/app/components/user-select-dialog/user-select-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthUser, AuthUserService } from 'src/app/services/auth-user/auth-user.service';
import {emojiValidator, getDisplayName} from "../../../../shared";

@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.css']
})
export class TeamDetailsComponent implements OnInit {
  form: FormGroup;

  team: TeamEditModel;
  users: UserModel[];
  teamLeaders: UserModel[];
  availableUsers: UserModel[];
  authUser: AuthUser;

  initialized: boolean;
  editMode: boolean;

  canEditSelfValues = [{ value: false, label: 'GLOBAL.NO' }, { value: true, label: 'GLOBAL.YES' }];
  canEditAllValues = [{ value: false, label: 'TEAM.EDIT_PERMISSIONS.TEAM' }, { value: true, label: 'TEAM.EDIT_PERMISSIONS.ALL' }];

  selectedUserIds = [];

  constructor(private route: ActivatedRoute, private apiClient: ApiClient,
    private formbulider: FormBuilder, private router: Router, public dialog: MatDialog, private authUserService: AuthUserService) { }

  ngOnInit() {
    this.authUser = this.authUserService.getUser();

    this.initForm();

    this.apiClient.getUsers().subscribe(users => {
      users.forEach(user => {
          user['name'] = getDisplayName(user);
      });

      this.users = users;
      this.filterUsers();
    });

    this.apiClient.getTeamLeaders().subscribe(teamLeaders => {
      this.teamLeaders = [];
      teamLeaders.forEach(teamLeader => {
        this.teamLeaders.push(new UserModel({ id: teamLeader.id, name: teamLeader.name }));
      });
    });

    this.route.params.subscribe(p => {
      var id = p['id'];
      if (id && id !== 'new') {
        this.initTeam(id);
      } else {
        this.initialized = true;
        this.initForm();
      }
    });
  }

  filterUsers() {
    if (this.users) {
      this.availableUsers = this.users.filter(user => {
        return (user.roleName !== 'RFID_APP_USER' &&  (!user.teamId || this.team && user.teamId == this.team.id));
      }).sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  initTeam(id) {
    this.apiClient.getTeam(id).subscribe(t => {
      this.team = t;
      this.filterUsers();
      this.initForm();
      this.setFormValues();
      this.editMode = true;
      this.initialized = true;
      this.selectedUserIds = this.team.userIds;
    });
  }

  setFormValues(){
    if (this.team) {
      this.form.controls.name.setValue(this.team.name);
      this.form.controls.teamLeaderId.setValue(this.team.teamLeader.id);
      this.form.controls.canEditSelf.setValue(this.team.canEditSelf);
      this.form.controls.canEditAll.setValue(this.team.canEditAll);
      this.form.controls.substituteTeamLeaderId.setValue(this.team.substituteTeamLeader ? this.team.substituteTeamLeader.id : null);
    }
  }

  initForm() {
    this.form = this.formbulider.group({
      name: ['', [Validators.required, emojiValidator()]],
      teamLeaderId: [''], // validation needs to be done external as disabled field would lead to deadlock
      canEditSelf: [false],
      canEditAll: [false],
      substituteTeamLeaderId: ['']
    });

    this.form.validator = this.requiredValidator;
  }

  requiredValidator(g: FormGroup): any {
    if (!g.get('teamLeaderId').value) {
      g.controls['teamLeaderId'].setErrors({ 'requiredValueMissing': true });
    }
  }

  onFormSubmit() {
    if (this.editMode) {
      this.team.name = this.form.controls.name.value;
      this.team.teamLeader.id = this.form.controls.teamLeaderId.value;
      this.team.teamLeader.name = null;
      this.team.canEditSelf = this.form.controls.canEditSelf.value;
      this.team.canEditAll = this.form.controls.canEditAll.value;
      this.team.substituteTeamLeader = this.form.controls.substituteTeamLeaderId.value ? new NameIdModel({ id: this.form.controls.substituteTeamLeaderId.value }) : null
      this.team.userIds = this.selectedUserIds;

      this.apiClient.updateTeam(this.team.id, this.team).subscribe(r => this.router.navigate(['/teams']));
    } else {
      var model = new TeamCreateModel({
        name: this.form.controls.name.value,
        teamLeader: new NameIdModel({ id: this.form.controls.teamLeaderId.value }),
        canEditSelf: this.form.controls.canEditSelf.value,
        canEditAll: this.form.controls.canEditAll.value,
        substituteTeamLeader: this.form.controls.substituteTeamLeaderId.value ? new NameIdModel({ id: this.form.controls.substituteTeamLeaderId.value }) : null,
        userIds: this.selectedUserIds
      });

      this.apiClient.createTeam(model).subscribe(r => this.router.navigate(['/teams']));
    }
  }

  previousState() {
    window.history.back();
  }

  openUserDialog() {
    const dialogRef = this.dialog.open(UserSelectDialog, {
      data: { selectedUserId: this.selectedUserIds, users: this.availableUsers }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedUserIds = result.map(r => parseInt(r));
      }
    });
  }

  getSelectedUsers() {
    if (!this.users) {
      return [];
    }
    var selected = this.selectedUserIds || [];
    var selectedUsers = this.users.filter(u => selected.indexOf(u.id) !== -1);
    if (this.team && this.team.teamLeader && selected.find(x => x == this.team.teamLeader.id) && !selectedUsers.find(x => x.id == this.team.teamLeader.id)) {
      selectedUsers.push(new UserModel({ id: this.team.teamLeader.id, name: this.team.teamLeader.name }));
    }
    if (this.team && this.team.substituteTeamLeader && selected.find(x => x == this.team.substituteTeamLeader.id) && !selectedUsers.find(x => x.id == this.team.substituteTeamLeader.id)) {
      selectedUsers.push(new UserModel({ id: this.team.substituteTeamLeader.id, name: this.team.substituteTeamLeader.name }));
    }
    return selectedUsers.sort((a, b) => a.name.localeCompare(b.name));
  }

  getAvailableTeamLeaders() {
    if (!this.teamLeaders) {
      return [];
    }
    return this.teamLeaders.filter(w => !this.form.controls.substituteTeamLeaderId || w.id !== this.form.controls.substituteTeamLeaderId.value);
  }

  getAvailableSubstituteTeamLeaders() {
    if (!this.teamLeaders) {
      return [];
    }
    return this.teamLeaders.filter(x => !this.form.controls.teamLeaderId || x.id !== this.form.controls.teamLeaderId.value);
  }

  getAvailableSubstituteTeamLeadersInMembers() {
    if (!this.users) {
      return [];
    }
    return this.getSelectedUsers().filter(x => x.roleName === 'USER' || x.roleName === 'STANDORT_ADMIN');
  }

  checkCanEdit() {
    return this.authUser.role === "SUPER_ADMIN";
  }

  checkCanEditSubstitute() {
    return this.authUser.role === "SUPER_ADMIN" || !this.team.substituteTeamLeader || this.authUser.userId !== this.team.substituteTeamLeader.id;
  }
}
