import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VacationListComponent } from './vacation-list/vacation-list.component';
import { AuthGuard } from 'src/app/auth-guard';
import { VacationDetailsComponent } from './vacation-details/vacation-details.component';


const routes: Routes = [
  {
    path: 'vacations',
    component: VacationListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'ABSENCE.TITLE'
    }
  },
  {
    path: 'vacations/:id',
    component: VacationDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'ABSENCE.TITLE'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VacationRoutingModule { }
