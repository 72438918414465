import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DateAdapter, MatPaginatorIntl } from "@angular/material";
import { TranslatedMatPaginatorIntl } from "./translated-mat-paginator-intl";

export class Translation {
    static languages: string[] = ['EN', 'DE'];
    static defaultLanguage: string = 'EN';
}

@Injectable({
    providedIn: 'root'
  })
export class LanguageConfigurator {
    languageSettingsKey: string = 'SETTINGS.LANGUAGE';
    constructor(private translationService: TranslateService, private dateAdapter: DateAdapter<Date>, private matPaginatorIntl: MatPaginatorIntl) {
        translationService.addLangs(Translation.languages);
        translationService.setDefaultLang(this.getCurrentLanguage());
    }

    initLanguage() { 
        var language = this.getCurrentLanguage();
        this.setLanquage(language);
    }

    setLanquage(language: string): void {  
        this.translationService.use(language);
        this.dateAdapter.setLocale(language.toLowerCase());
        var translatedMatPaginatorIntl = this.matPaginatorIntl as TranslatedMatPaginatorIntl
        if(translatedMatPaginatorIntl != null) {
            translatedMatPaginatorIntl.setTranslations();
        }
        if(Translation.languages.length > 1) {
            localStorage.setItem(this.languageSettingsKey, language);
        }
    }

    getCurrentLanguage(): string {
        var lanuage = localStorage.getItem(this.languageSettingsKey);
        if(!lanuage) {
            var browserLang = this.translationService.getBrowserLang();
            browserLang = browserLang ? browserLang.toUpperCase() : '';
            lanuage = Translation.languages.indexOf(browserLang) !== -1 ? browserLang : Translation.defaultLanguage;
        }
        return lanuage;
    }
}