import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiClient, WorktimeApprovalModel} from 'src/app/services/api.client';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ConfirmationDialogService} from 'src/app/services/comfirmation-dialog.service';
import {MessageDialogService} from 'src/app/services/message-dialog.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {saveAs} from 'file-saver';
import {Params} from '@angular/router';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {LanguageConfigurator} from 'src/app/services/translation';
import {FilterService, FilterServiceModel} from 'src/app/services/filter-service';
import {getDisplayName} from 'src/app/shared';
import {AuthUser, AuthUserService} from 'src/app/services/auth-user/auth-user.service';
import {WorktimeDetailsDialog} from './worktime-details-dialog/worktime-details-dialog.component';

@Component({
  selector: 'app-worktime-approval-list',
  templateUrl: './worktime-approval-list.component.html',
  styleUrls: ['./worktime-approval-list.component.scss']
})
export class WorktimeApprovalListComponent implements OnInit, OnDestroy {
  monthFormat = 'MMMM YYYY';

  approvals = new MatTableDataSource<WorktimeApprovalModel>();
  displayedColumns: string[] = ['status', 'userName', 'month', 'vacations', 'holidays', 'sickDays', 'specialLeaveDays', 'hoursAccount', 'modified',
   // 'hasWarnings', 'isApproved',
    'actions'];

  availableMonths: any[];
  month: string;
  userId?: number;
  authUser: AuthUser;
  canEditSelf: boolean;
  onlyActive?: boolean;
  details?: any;
  doNotReload?: boolean;

  filterModel: FilterServiceModel;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
    constructor(private apiClient: ApiClient, private confirmService: ConfirmationDialogService, private messageService: MessageDialogService, public dialog: MatDialog,
      private translateService: TranslateService, private languageConfigurator: LanguageConfigurator, filterService: FilterService, private authUserService: AuthUserService) {
      this.availableMonths = [{ value: '' }];
      this.apiClient.getFirstApprovalMonth().subscribe(firstApprovalMonth => {
        var itteratorMonth = moment();
        while (itteratorMonth.toDate() >= firstApprovalMonth) {
          this.availableMonths.push({ value: itteratorMonth.format(this.monthFormat), year: itteratorMonth.year(), month: itteratorMonth.month() + 1 });
          itteratorMonth = itteratorMonth.add(-1, 'month');
        }
      });
      this.filterModel = filterService.createFilter(this.approvals, (p) => this.validateFilterValues(p), () => this.applyFilter());
      this.approvals.sortingDataAccessor = (data: any, sortHeaderId: string) => {
        var value: any;
        switch (sortHeaderId) {
          case "userName":
            value = data.user.name;
            break;
          default:
            value = data[sortHeaderId];
        }
        return typeof value === "string" ? value.toLowerCase() : value;
      };
      this.approvals.filterPredicate = (data, filter) => {
        return filterService.filterTableData([
          data.user.name,
          data.hoursAccount,
          translateService.instant(`GLOBAL.MONTH_YEAR.${data['month_number']}`, {year:data['year']})
        ], filter);
      };
  }

  getColumns() {
    if(this.onlyActive){
      return this.displayedColumns.filter(x => x !== 'modified');
    }
    return this.displayedColumns;
  }

  ngOnDestroy(): void {
    this.filterModel.destroy();
  }

  isValidDate(d) {
    return d && d.isValid && d.isValid();
  }

  validateFilterValues(params: Params) {
    if (params['month']) {
      var parsed = moment(params['month'], this.monthFormat);
      if(parsed.isValid()){
        this.month = parsed.format(this.monthFormat);
      }
    }

    this.userId = parseInt(params['userId']);
    if(isNaN(this.userId)) {
      this.userId = undefined;
    }

    this.onlyActive = params['onlyActive'] === 'false' ? false : true;

    try
    {
      this.details = JSON.parse(params['details']);
    } catch {
      this.details = undefined;
    }
  }

  ngOnInit() {
    this.approvals.paginator = this.paginator;
    this.approvals.sort = this.sort;
    this.filterModel.loadFilterValuesAndApply();
    this.authUser = this.authUserService.getUser();
    this.apiClient.getUserCanEditSelf().subscribe(canEditSelf => {
      this.canEditSelf = canEditSelf;
    });
  }

  filter() {
    var params = {};

    params['month'] = this.month;
    params['userId'] = this.userId;
    params['onlyActive'] = this.onlyActive;
    params['details'] = this.details;

    this.filterModel.applyFilter(params);
  }

  applyQuickFilter(filterValue: string) {
    this.filterModel.applyQuickFilter(filterValue);
  }

  applyFilter() {
    if (this.doNotReload) {
      this.checkIfOpenDetails();
      this.doNotReload = false;

      return;
    }

    this.apiClient.getWorktimeApprovals(this.month ? moment(this.month, this.monthFormat).toDate() : undefined, this.userId, !this.onlyActive).subscribe(worktimeApprovals => {
      worktimeApprovals.forEach(item => {
        item['month_number'] = moment(item.month).month() + 1;
        item['year'] = moment(item.month).year();
        this.translateService.get('GLOBAL.MONTH_YEAR.'+ item['month_number'], {year: item['year'] }).subscribe(tt => {
          item['displayMonth'] = tt;
        });
        item['status'] = item.hasWarnings ? 1 : item.isApproved ? 2 : 0;
      });
      this.approvals.data = worktimeApprovals;
      this.checkIfOpenDetails();
    });
  }

  checkIfOpenDetails() {
    if (this.details) {
      var data = this.approvals.data.find(item => {
        return moment(item.month).format(this.monthFormat) ===  moment(this.details.month).format(this.monthFormat) && item.user.id == this.details.userId;
      });

      if (data) {
        const dialogRef = this.dialog.open(WorktimeDetailsDialog, {
          data: data,
          width: '80%',
          //height: '88%'
        });

        dialogRef.afterClosed().subscribe(result => {
          if (!result) {
            return;
          }

          this.details = undefined;
          if(!result.update) {
            this.doNotReload = true;
          }
          this.filter();
        });
      } else {
        this.details = undefined;
        this.filter();
      }
    }
  }

  changeStatus(item, status) {
    const confirmText = status ? 'APPROVE_MONTH_CONFIRM' : 'OPEN_MONTH_CONFIRM';
    this.confirmService.translateAndOpen('WORKTIME.' + confirmText, { month: item.displayMonth, user_name: item.user.name }).subscribe(r => {
      if(r) {
        this.apiClient.changeWorktimeApprovalStatus(item.user.id, item.month, status)
        .subscribe(
          _ => {
            this.applyFilter();
          }
        );
      }
    });
  }

  openDetails(data): void {
    this.details = JSON.stringify({month: data.month, userId: data.user.id});

    this.doNotReload = true;
    this.filter();
  }

  openAddMonth() {
    this.apiClient.getUsers().subscribe(users => {
      users.forEach(user => {
        user['name'] = getDisplayName(user);
      });
      var usersWithData = {};
      this.approvals.data.forEach(w => {
        if (!usersWithData[w.user.id]) {
          usersWithData[w.user.id] = [];
        }

        usersWithData[w.user.id].push(moment(w.month).format('MMMM YYYY'));
      });

      this.apiClient.getEmptyApprovalMonths().subscribe(r => {
        const dialogRef = this.dialog.open(WorktimeApprovalAddMonthDialog, { data: { users: users.sort((a, b) => a.name.localeCompare(b.name)), usersWithData: r } });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.apiClient.changeWorktimeApprovalStatus(result.userId, result.month, false).subscribe(cr => {
              this.applyFilter();
            });
          }
        });
      });
    });
  }

  export(userId, month){
    this.apiClient.exportForMonthAndUser(month, userId, this.languageConfigurator.getCurrentLanguage()).subscribe(e =>{
      if(e.fileName) {
        saveAs(e.data, e.fileName);
      }
    });
  }

  delete(item) {
    this.confirmService.translateAndOpen('WORKTIME.DELETE_MONTH_CONFIRM', { month: item.displayMonth }).subscribe(r => {
      if(r) {
        this.apiClient.deleteDataForMonth(item.user.id, item.month).subscribe(
          _ => {
            this.applyFilter();
          }
        );
      }
    });
  }

  checkCanEdit() {
    return this.authUser.role === "SUPER_ADMIN" || this.authUser.role === "WORKTIME_ADMIN";
  }

  checkCanEditItem(item) {
    if (this.authUser.role === "SUPER_ADMIN")
      return true;
    else if (this.authUser.role === "WORKTIME_ADMIN") {
      if (item.user.id === this.authUser.userId) {
        return this.canEditSelf;
      }
      return true;
    }
    return false;
  }
}

@Component({
  selector: 'worktime-approval-add-month-dialog',
  templateUrl: './worktime-approval-add-month-dialog.html',
  styleUrls: ['./worktime-approval-list.component.scss']
})
export class WorktimeApprovalAddMonthDialog {
  month: any;
  userId: number;

  users: any;
  usersWithData: any;

  months: any[];

  dateFormat: string = 'MMMM YYYY';

  constructor(public dialogRef: MatDialogRef<WorktimeApprovalAddMonthDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.users = data.users;
    this.usersWithData = data.usersWithData;

    if(this.users.length > 0) {
      this.userId = this.users[0].id;
    }

    this.months = [];
    for(var i = 0; i < 24; i++) {
      var d = moment().add(-i, 'month');
      this.months.push({ value: d.format(this.dateFormat), month: d.month() + 1, year: d.year() });
    }
    this.month = this.months[0]
  }

  filteredUsers() {
    return this.users.filter(u => this.usersWithData[u.id]);
  }

  filteredMonths() {
    if (this.userId) {
      return this.months.filter(m => {
        return this.usersWithData[this.userId] && this.usersWithData[this.userId].indexOf(moment(m.value, this.dateFormat).format('YYYY-MM-DDT00:00:00')) !== -1;
      });
    }
    return [];
  }

  cancel(): void {
    this.dialogRef.close();
  }

  add() {
    this.dialogRef.close({userId: this.userId, month: moment(this.month, this.dateFormat).toDate()});
  }
}
