import { Injectable } from "@angular/core";
import { ConfirmationDialogComponent } from "../components/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { flatMap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
  })
export class ConfirmationDialogService {

    constructor(public dialog: MatDialog, private translateService: TranslateService) {}
    
    openDialog(message): Observable<any> {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '350px',
          data: message
        });

        return dialogRef.afterClosed();
    }
    
    translateAndOpen(message: string, interpolateParams?: Object) : Observable<any> {
      return this.translateService.get(message, interpolateParams).pipe(flatMap(text => {
        return this.openDialog(text);
      }));
    }
}