import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
  })
export class ErrorMesageService {

    constructor(private snackBar: MatSnackBar, private translateService: TranslateService) { }

    showError(message: string): void {
        this.translateService.get(message).subscribe(messageText => {
            this.snackBar.open(messageText, null, {
                verticalPosition: 'top',
                panelClass: 'error-message',
                duration: 5000
             });
        });
    }
}
