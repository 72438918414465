import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VacationListComponent } from './vacation-list/vacation-list.component';
import { AuthGuard } from 'src/app/auth-guard';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { VacationRoutingModule } from './vacation-routing.module';
import { VacationDetailsComponent } from './vacation-details/vacation-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { VacationCalendarComponent } from './vacation-calendar/vacation-calendar.component';
import { VacationCreationDialogComponent } from './vacation-creation-dialog/vacation-creation-dialog.component';



@NgModule({
  declarations: [
    VacationListComponent,
    VacationDetailsComponent,
    VacationCalendarComponent,
    VacationCreationDialogComponent
  ],
  entryComponents: [
    VacationCreationDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    VacationRoutingModule,
    MaterialModule,
    TranslateModule,
  ],
  providers: [AuthGuard],
})
export class VacationModule { }
