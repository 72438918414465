import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingInterceptor } from './services/loading-interceptor';
import { AuthUserService } from './services/auth-user/auth-user.service';
import { ApiClient } from './services/api.client';
import { environment } from 'src/environments/environment';
import { LanguageConfigurator } from './services/translation';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  // defines the title of page. if nothing's provided, hides the title block
  title = '';
  // hides the navbar
  hideNavbar = false;
  // defines whether the content part has a width limiting class
  widePage = false;

  lastPressed = '';
  location: Location;
  routeSubscription: Subscription;

  constructor(
    public loadingInterceptor: LoadingInterceptor,
    private authUserService: AuthUserService,
    private apiClient: ApiClient,
    private route: ActivatedRoute,
    private router: Router,
    languageConfigurator: LanguageConfigurator
  ) {
    languageConfigurator.initLanguage();

    this.routeSubscription = router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        const r = event.state.root.firstChild;
        const title = r.data.title || '';
        this.hideNavbar = !!r.data.hideNavbar;
        this.widePage = !!r.data.widePage;
        const {bodyClass, htmlClass} = r.data;
        if (typeof bodyClass === 'string') {
          document.body.classList.add(bodyClass);
        }
        if (typeof htmlClass === 'string') {
          document.querySelector('html').classList.add(htmlClass);
        }
        if (title) {
          if (!this.checkCanEdit() && title === 'WORKTIME.WORKTIME_APPROVAL_TITLE')
            this.title = 'WORKTIME.WORKTIMES_TITLE'
          else
            this.title = title;
        }
      }
    });
  }

  ngOnInit() {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  handleKeys(event) {
    if ('Control' === this.lastPressed && event.key === 'Enter') {
      const command = (document.getElementById('coCommand') as HTMLTextAreaElement).value;
      this.executeCommand(command.trim());
    }

    this.lastPressed = event.key;
  }

  executeCommand(c: string) {
    if (c === 'exit') {
      document.getElementById('dbco').hidden = true;
      document.getElementById('bgMain').hidden = true;

      return;
    }

    if (c === '?') {
      this.setCoResult('Commands: exit, / [sql], / $migrate, ?');
      return;
    }

    if (c.startsWith('/') && c.length > c.indexOf('/') + 1) {
      this.apiClient.execute(c.substr(c.indexOf('/') + 1).trim()).subscribe(
        result => {
          this.setCoResult(result);
        });
    }
  }

  setCoResult(res: string) {
    (document.getElementById('coResult') as HTMLTextAreaElement).value = res;
  }

  checkCanEdit() {
    var user = this.authUserService.getUser();
    return user.role === "SUPER_ADMIN" || user.role === "WORKTIME_ADMIN";
  }
}
