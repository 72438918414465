import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';

@Component({
  selector: 'app-correction-table',
  templateUrl: './correction-table.component.html',
  styleUrls: ['./correction-table.component.scss']
})
export class CorrectionTableComponent implements OnInit {
  @Input() displayedCols: string[]
  @Input() data: MatTableDataSource<any>

  constructor() { }

  ngOnInit() {
  }

  getDuration(miliseconds) {
    const date = new Date(miliseconds);

    const seconds = date.getUTCSeconds();
    const minutes = date.getUTCMinutes();
    const hours = date.getUTCHours();

    let result = '';
    if (seconds > 0) {
      result = `${seconds}s`;
    }
    if (minutes > 0 || hours > 0) {
      result = `${minutes}m ` + result;
    }
    if (hours > 0) {
      result = `${hours}h ` + result;
    }
    return result;
  }

  isPause(worktime: any, idx: number) {
    if (idx === 0) {
      return false
    }
    if (idx !== 0 || idx) {
      let duration = 0
      const prevWorkTime = this.data.data[idx - 1]
      // @ts-ignore
      if (this.getMinutesFromDate(worktime.details.start.time) > this.getMinutesFromDate(prevWorkTime.details.stop.time)) {
        // @ts-ignore
        const minutesStart = this.getMinutesFromDate(prevWorkTime.details.stop.time);
        // @ts-ignore
        const minutesStop =  this.getMinutesFromDate(worktime.details.start.time)
        duration = minutesStop - minutesStart
      }
      const mins = duration % 60 < 10 ? '0' + duration % 60 : duration % 60
      const hours = Math.trunc(duration / 60) < 10 ? '0' + Math.trunc(duration / 60) : Math.trunc(duration / 60)
      return duration !== 0 && duration > 0 ? `${hours} : ${mins}` : false
    }
  }

  getMinutesFromDate(date: Date) {
    return date.getHours() * 60 + date.getMinutes()
  }

}
