import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from '../../auth-guard';
import { MaterialModule } from '../../material.module';
import { StandortDetailComponent } from './standort-detail/standort-detail.component';
import { StandortListComponent } from './standort-list/standort-list.component';
import { StandortRoutingModule } from './standort-routing.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StandortRoutingModule,
    MaterialModule,
    TranslateModule
  ],
  providers: [AuthGuard],
  declarations: [StandortListComponent, StandortDetailComponent]})
export class StandortModule {
}
