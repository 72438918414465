import { Component, OnInit, Input, Inject } from "@angular/core";
import { ApiClient, WorkingHoursModel, HoursByDay } from "src/app/services/api.client";
import { MatTableDataSource } from "@angular/material/table";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConfirmationDialogService } from "src/app/services/comfirmation-dialog.service";
import * as moment from 'moment';
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from 'rxjs';

@Component({
    selector: 'user-working-hours',
    templateUrl: './user-working-hours.component.html',
    styleUrls: ['./user-working-hours.component.css']
  })
  export class UserWorkingHours implements OnInit {
    @Input() userId: number;

    workingHours = new MatTableDataSource<WorkingHoursModel>();

    displayedColumns = ['effectiveDate',
    'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday',
    'modified', 'actions'];

    constructor(private apiClient: ApiClient, public dialog: MatDialog,
      private confirmService: ConfirmationDialogService, private translationService: TranslateService) { }
  
    ngOnInit() {
      this.loadWOrkingHours();
    }

    loadWOrkingHours() {
      this.apiClient.getWorkingHoursForUser(this.userId).subscribe(workingHours => {
        this.workingHours.data = workingHours;
      });
    }

    applyFilter(filterValue: string) {
      this.workingHours.filter = filterValue.trim().toLowerCase();
    }

    edit(item: WorkingHoursModel) {
      const dialogRef = this.dialog.open(UserWorkingHoursEditDialog, { data: item || {} });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.apiClient.saveWorkingHoursForUser(this.userId, result).subscribe(r => {
            this.loadWOrkingHours();
          });
        }
      });
    }

    delete(item: WorkingHoursModel) {
      this.confirmService.translateAndOpen('USER.WORKING_HOURS.DELETE_CONFIRM', { effectiveDate: moment(item.effectiveDate).format('YYYY-MM-DD') }).subscribe(r => {
        if(r) {
          this.apiClient.deleteWorkingHours(this.userId, item.id).subscribe(cr => {
            this.loadWOrkingHours();
          });
        }
      });
    }
  }

  @Component({
    selector: 'user-working-hours-edit-dialog',
    templateUrl: 'user-working-hours-edit.html',
    styleUrls: ['./user-working-hours.component.css']
  })
  export class UserWorkingHoursEditDialog {
    form: FormGroup;

    constructor(
      public dialogRef: MatDialogRef<UserWorkingHoursEditDialog>,
      @Inject(MAT_DIALOG_DATA) public data: WorkingHoursModel,
      private apiClient: ApiClient,
      private formbulider: FormBuilder
    ) {
      this.initForm();
      this.setFormValues(data);
    }

    initForm() {
      this.form = this.formbulider.group({
        effectiveDate: ['', [Validators.required]],
        monday: ['', [Validators.min(0)]],
        tuesday: ['', [Validators.min(0)]],
        wednesday: ['', [Validators.min(0)]],
        thursday: ['', [Validators.min(0)]],
        friday: ['', [Validators.min(0)]],
        saturday: ['', [Validators.min(0)]],
        sunday: ['', [Validators.min(0)]],
        weeklyHours: ['', [Validators.min(0)]]
      });
    }

    setFormValues(data: WorkingHoursModel) {
      if (data.id > 0) {
        this.form.controls.effectiveDate.setValue(data.effectiveDate);
        this.form.controls.monday.setValue(data.hoursByDay.monday);
        this.form.controls.tuesday.setValue(data.hoursByDay.tuesday);
        this.form.controls.wednesday.setValue(data.hoursByDay.wednesday);
        this.form.controls.thursday.setValue(data.hoursByDay.thursday);
        this.form.controls.friday.setValue(data.hoursByDay.friday);
        this.form.controls.saturday.setValue(data.hoursByDay.saturday);
        this.form.controls.sunday.setValue(data.hoursByDay.sunday);

        var total = data.hoursByDay.monday + 
                    data.hoursByDay.tuesday +
                    data.hoursByDay.wednesday +
                    data.hoursByDay.thursday +
                    data.hoursByDay.friday;

        this.form.controls.weeklyHours.setValue((total / 60));
      }
    }

    onNoClick(): void {
      this.dialogRef.close(false);
    }

    editMode() {
      return this.data.id > 0;
    }

    canSetWeeklyHours() {
      var weeklyHours = parseInt(this.form.controls.weeklyHours.value);
      return weeklyHours >= 0;
    }

    setWeeklyHours() {
      var weeklyHours = parseInt(this.form.controls.weeklyHours.value);
      var perDay = weeklyHours * 60 / 5;

      this.form.controls.monday.setValue(perDay);
      this.form.controls.tuesday.setValue(perDay);
      this.form.controls.wednesday.setValue(perDay);
      this.form.controls.thursday.setValue(perDay);
      this.form.controls.friday.setValue(perDay);
      this.form.controls.saturday.setValue(0);
      this.form.controls.sunday.setValue(0);
    }

    save() {
      if (this.form.valid) {
        this.dialogRef.close(new WorkingHoursModel({
          id: this.data.id,
          effectiveDate: this.form.controls.effectiveDate.value,
          hoursByDay: new HoursByDay({
            monday: parseInt(this.form.controls.monday.value) || 0,
            tuesday: parseInt(this.form.controls.tuesday.value) || 0,
            wednesday: parseInt(this.form.controls.wednesday.value) || 0,
            thursday: parseInt(this.form.controls.thursday.value) || 0,
            friday: parseInt(this.form.controls.friday.value) || 0,
            saturday: parseInt(this.form.controls.saturday.value) || 0,
            sunday: parseInt(this.form.controls.sunday.value) || 0
          })
        }));
      }
    }
  }
