import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ApiClient, UserVacationModel, ChangeVacationStatusModel, VacationStatus } from 'src/app/services/api.client';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router, Params } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { FilterService, FilterServiceModel } from 'src/app/services/filter-service';
import { ConfirmationDialogService } from '../../../services/comfirmation-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { VacationCreationDialogComponent } from '../vacation-creation-dialog/vacation-creation-dialog.component';
import { AuthUser, AuthUserService } from 'src/app/services/auth-user/auth-user.service';

@Component({
  selector: 'app-vacation-list',
  templateUrl: './vacation-list.component.html',
  styleUrls: ['./vacation-list.component.css']
})
export class VacationListComponent implements OnInit, OnDestroy {
  vacations = new MatTableDataSource<UserVacationModel>();
  displayedColumns: string[] = ['status', 'userName', 'type', 'from', 'to', 'modified', 'actions'];

  from?: Date;
  to?: Date;
  onlyNotApproved: boolean;
  authUser: AuthUser;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  filterModel: FilterServiceModel;
    constructor(private apiClient: ApiClient,
                filterService: FilterService,
                private router: Router,
                private confirmService: ConfirmationDialogService,
                private readonly dialog: MatDialog,
                private authUserService: AuthUserService
    ) {
      this.filterModel = filterService.createFilter(this.vacations, (p) => this.validateFilterValues(p), () => this.applyFilter());
      this.vacations.sortingDataAccessor = (data: any, sortHeaderId: string) => {
        var value: any;
        switch (sortHeaderId) {
          case "userName":
            value = data.user.name;
            break;
          case "modified":
            value = data.modifiedDate;
            break;
          default:
            value = data[sortHeaderId];
        }
        return typeof value === "string" ? value.toLowerCase() : value;
      };
      this.vacations.filterPredicate = (data, filter) => {
        return filterService.filterTableData([
          data.user.name,
          data.from.toISOString(),
          data.to.toISOString(),
          `ABSENCE.STATUSES.${data.status}`,
          `ABSENCE.TYPES.${data.type}`
        ], filter);
      };
  }

  ngOnDestroy(): void {
    this.filterModel.destroy();
  }

  isValidDate(d){
    return d instanceof Date && !isNaN(d.getUTCMilliseconds());
  }

  validateFilterValues(params: Params) {
    this.from = new Date(params['from']);
    if(!this.isValidDate(this.from)) {
      this.from = undefined;
    }
    this.to = new Date(params['to']);
    if(!this.isValidDate(this.to)) {
      this.to = undefined;
    }

    this.onlyNotApproved = params['onlyNotApproved'] === 'true';
    if(params['onlyNotApproved'] == undefined) {
      this.onlyNotApproved = true;
    }
  }

  initFilter() {
    this.filterModel.loadFilterValuesAndApply();
  }

  applyQuickFilter(filterValue: string) {
    this.filterModel.applyQuickFilter(filterValue);
  }

  applyFilter() {
    this.apiClient.getVacations(this.from, this.to, undefined, !this.onlyNotApproved).subscribe(vacations => {
      this.vacations.data = vacations;
    });
  }

  ngOnInit() {
    this.vacations.paginator = this.paginator;
    this.vacations.sort = this.sort;
    this.authUser = this.authUserService.getUser();
    this.initFilter();
  }

  filter() {
    var params = {};
    if(this.from) {
      var from = moment(this.from);
      params['from'] = `${from.year()}-${from.month() + 1}-${from.date()}`;
    }
    if(this.to) {
      var to = moment(this.to);
      params['to'] = `${to.year()}-${to.month() + 1}-${to.date()}`;
    }
    params['onlyNotApproved'] = this.onlyNotApproved;

    this.filterModel.applyFilter(params);
  }

  delete(vacation: UserVacationModel) {
    this.confirmService.translateAndOpen('ABSENCE.DELETE_CONFIRM').subscribe(r => {
      if (r) {
        this.apiClient.deleteVacation(vacation.id).subscribe(
          res => {
            this.applyFilter();
          });
      }
    });
  }

  changeStatus(vacation: UserVacationModel, status: VacationStatus) {
    var model = new ChangeVacationStatusModel({ status: status });
    this.apiClient.changeVacationStatus(vacation.id, model).subscribe(r => {
      // reload vacations
      this.initFilter();
    });
  }

  approve(vacation: UserVacationModel) {
    this.changeStatus(vacation, VacationStatus.Approved);
  }

  reject(vacation: UserVacationModel) {
    this.changeStatus(vacation, VacationStatus.Rejected);
  }

  openAbsenceCreationDialog() {
      const dialogConfig: MatDialogConfig = {
        width: 'fit-content',
        height: 'fit-content',
      };
    this.dialog.open(VacationCreationDialogComponent, dialogConfig).afterClosed().subscribe(
      res => {
        if (res) {
          this.filter();
        }
      }
    );
  }

  checkCanChangeStatus(item) {
    if (this.authUser.role === "SUPER_ADMIN")
      return item.status === 'Waiting';
    else if (item.type === 'SickLaeve')
      return false;
    else
      return item.status === 'Waiting';
  }
}
