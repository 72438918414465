import { Injectable } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { MatTableDataSource } from "@angular/material";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { distinct } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class FilterService {
    constructor(private router: Router, private route: ActivatedRoute, private translateService: TranslateService) {
    }

    createFilter(matTableDataSource: MatTableDataSource<any>, validateFilterValues: any, filterApplied: any) {
        return new FilterServiceModel(matTableDataSource, validateFilterValues, filterApplied, this.router, this.route);
    }

    filterTableData(values: string[], filter: string) : boolean {
        if(!filter) {
            return true;
        }

        let f = filter.toLowerCase();
        return values.find(data => data && this.translateService.instant(data).toLowerCase().indexOf(f) != -1) != null;
    }

}

export class FilterServiceModel {
    doNotFetchFromServer: boolean;
    quickFilterValue: string = '';
    paramsSubscription: Subscription;

    constructor(private dataSource: MatTableDataSource<any>, private validateFilterValues: any, private filterApplied: any, private router: Router, private route: ActivatedRoute) {
    }

    loadFilterValuesAndApply() {
        this.paramsSubscription = this.route.queryParams.pipe(distinct()).subscribe((params: Params) => {
            this.validateFilterValues(params);
            this.quickFilterValue = params['quickFilter'] || '';
            this.dataSource.filter = this.quickFilterValue;
            if(!this.doNotFetchFromServer) {
                this.doNotFetchFromServer = false;
                this.filterApplied();
            }
        });
    }

    destroy() {
      this.paramsSubscription.unsubscribe();
    }

    applyQuickFilter(value: string) {
        this.quickFilterValue = value.trim().toLowerCase();
        this.dataSource.filter = this.quickFilterValue;
        
        this.doNotFetchFromServer = true;
        this.router.navigate([], 
        {
            relativeTo: this.route,
            queryParams: {quickFilter: value},
            queryParamsHandling: 'merge',
            replaceUrl: true
        });
    }

    applyFilter(params: {}) {
        params['quickFilter'] = this.dataSource.filter;

        this.doNotFetchFromServer = false;
        this.router.navigate([], 
        {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge'
        }).then(n => {
            if (n == null) {
            this.filterApplied();
            }
        });
    }
}