import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth-guard';
import { WorktimeListComponent } from './worktime-list/worktime-list.component';
import { WorktimeDetailsComponent } from './worktime-details/worktime-details.component';
import { WorktimeApprovalListComponent } from './worktime-approval-list/worktime-approval-list.component';
import {CorrectionViewComponent} from './correction-view/correction-view.component';

const routes: Routes = [
  {
    path: 'worktime',
    component: WorktimeListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'WORKTIME.TITLE'
    }
  },
  {
    path: 'worktime/:id',
    component: WorktimeDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'WORKTIME.TITLE'
    }
  },
  {
    path: 'worktime/new',
    component: WorktimeDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'WORKTIME.TITLE'
    }
  },
  {
    path: 'worktimeApproval',
    component: WorktimeApprovalListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'WORKTIME.WORKTIME_APPROVAL_TITLE'
    }
  },
  {
    path: 'correctionRequests',
    component: WorktimeApprovalListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'WORKTIME.CORRECTION_REQUESTS_TITLE',
    }
  },
  {
    path: 'requests',
    component: WorktimeListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'WORKTIME.CORRECTION_REQUESTS_TITLE',
    }
  },
  {
    path: 'correctionApproval',
    component: CorrectionViewComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'CORRECTION_REQUESTS.TOP-TITLE'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorktimeRoutingModule { }
