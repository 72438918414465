import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private loadingsCount: number = 0;

    public get isLoading(): boolean {
        return this.loadingsCount > 0;
    }

    constructor() { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loadingsCount++;
        return next.handle(req).pipe(
            finalize(() => this.loadingsCount--)
        );
    }
}