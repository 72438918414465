import {Component} from "@angular/core";
import { ApiClient } from "src/app/services/api.client";
declare var require: any;

@Component({
  selector: 'version-info',
  template: `
    <p>Frontend: <strong>{{feVersion}}</strong></p>
    <p>Backend: <strong>{{beVersion}}</strong></p>
  `,
  styleUrls: ['./version-info.component.scss']
})
export class VersionInfoComponent {
  feVersion = require('../../../../package.json').version || 'unknown';
  beVersion = 'loading...';

  constructor(public apiClient: ApiClient) {
    apiClient.getVersion().subscribe((value) => {
      this.beVersion = value['value'];
    },
      (err) => {
      this.beVersion = 'unknown';
        console.log("Cannot obtain the backend version");
      });
  }
}
