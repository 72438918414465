import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AdditionalAbsenceInformationDefinition, AdditionalAbsenceInformationType, ApiClient, NameIdModel, UserIdentModel, VacationCreateForUserModel, VacationType} from '../../../services/api.client';
import {Observable} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {Moment} from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {dateRangeValidator} from '../../../shared';
import { forEach } from 'lodash';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-vacation-creation-dialog',
  templateUrl: './vacation-creation-dialog.component.html',
  styleUrls: ['./vacation-creation-dialog.component.css']
})
export class VacationCreationDialogComponent implements OnInit {
  users$: Observable<NameIdModel[]>;
  additionalAbsenceInformationDefinitions: AdditionalAbsenceInformationDefinition[];
  absenceForm: FormGroup;
  absenceTypes = [
    VacationType.SickLaeve,
    VacationType.ChildSickLeave,
    VacationType.SpecialLeave,
    VacationType.Holiday
  ];
  userInitialValue: string;
  minStartDate: Moment = moment().startOf('day');
  minEndDate: Moment = moment().startOf('day');

  constructor(
    public readonly dialogRef: MatDialogRef<VacationCreationDialogComponent>,
    public readonly api: ApiClient,
    private readonly formBuilder: FormBuilder,
    public readonly translationService: TranslateService,
  ) { }

  ngOnInit() {
      this.users$ = this.api.getUserTeamMembers().pipe(
        map(results => results.sort((a, b) => a.name.localeCompare(b.name)))
      )
      this.buildForm();
  }

  addAbsence() {
    var additionalAbsenceInformation: { [id: string]: string; } = {};
    this.additionalAbsenceInformationDefinitions.forEach((definition) => {
      var valueRaw = this.absenceForm.get(definition.name).value;
      var valueString: string = '';
      switch (definition.type) {
        case AdditionalAbsenceInformationType.Boolean:
          valueString = valueRaw == null ? 'Null' : (valueRaw ? 'True' : 'False');
          break;
        case AdditionalAbsenceInformationType.Date:
          valueString = valueRaw == null ? 'Null' : moment(valueRaw).format('YYYY-MM-DD');
          break;
        default:
          valueString = valueRaw == null ? 'Null' : this.absenceForm.get(definition.name).value;
          break;
      }
      additionalAbsenceInformation[definition.name] = valueString;
    });
    const {userId, start, end, type} = this.absenceForm.value;
    this.api.createVacationForUser(new VacationCreateForUserModel({
      userId,
      type,
      from: start,
      to: end,
      additionalAbsenceInformation: additionalAbsenceInformation
    })).subscribe(() => this.dialogRef.close(true))
  }

  private buildForm(): void {
    this.absenceForm = this.formBuilder.group({
      userId: [null , Validators.required],
      type: [null, Validators.required],
      start: [this.minStartDate, Validators.required],
      end: [this.minEndDate, Validators.required]
    }, {
      validators: [dateRangeValidator('start', 'end', true)]
    });
  }

  patchValueToForm(id: number) {
    this.absenceForm.get('userId').patchValue(id);
    this.absenceForm.updateValueAndValidity()
  }

  getAdditionalAbsenceInformationDefinitions(type: VacationType) {
    this.api.getAdditionalAbsenceInformationDefinitions(type).subscribe((definitions) => {
      this.additionalAbsenceInformationDefinitions = definitions;
      //Add new form fields for each definition in additionalAbsenceInformationDefinitions
      definitions.forEach((definition) => {
        var validators = [];
        if (definition.isRequired) {
          validators.push(Validators.required);
        }
        if (definition.type === AdditionalAbsenceInformationType.Text && definition.possibleValues !== null) {
          validators.push(Validators.pattern(definition.possibleValues));
        }
        this.absenceForm.addControl(definition.name, this.formBuilder.control(null, validators));
        //Fill the form with the default values if they exist
        if (definition.defaultValue !== null) {
          var defaultValue = null;
          switch (definition.type) {
            case AdditionalAbsenceInformationType.Boolean:
              defaultValue = definition.defaultValue === 'True';
              break;
            case AdditionalAbsenceInformationType.Date:
              if (definition.defaultValue === 'Today') {
                var today = new Date();
                today.setHours(0, 0, 0, 0);
                defaultValue = today;
              }
              else {
                defaultValue = Date.parse(definition.defaultValue);
              }
              break;
            default:
              defaultValue = definition.defaultValue;
              break;
          }
          this.absenceForm.get(definition.name).patchValue(defaultValue);
        }
      });
    });
  }
}
