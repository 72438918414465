import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiClient, Standort, StandortCoordinatesModel } from '../../../services/api.client';
import {emojiValidator} from "../../../shared";

@Component({
  selector: 'app-standort-detail',
  templateUrl: './standort-detail.component.html',
  styleUrls: ['./standort-detail.component.css']
})
/** standort-detail component*/
export class StandortDetailComponent {

  standortForm: any;
  standortFormLabel: string;

  isSaving: boolean;
  standort: Standort;

  /** standort-detail ctor */
  constructor(private formbulider: FormBuilder, private activatedRoute: ActivatedRoute, private apiClient: ApiClient) { }

  ngOnInit() {
    this.isSaving = false;

    this.standortForm = this.formbulider.group({
      name: ['',[Validators.required, emojiValidator()]],
      description: ['', [emojiValidator()]],
      wlanId: ['', [emojiValidator()]],
      ssid: ['', [emojiValidator()]],
      latitude: [''],
      longitude: [''],
      radius: ['', [Validators.min(0)]]
    });

    this.activatedRoute.data.subscribe(({ standort, standortFormLabel }) => {
      this.standort = standort;
      this.standortFormLabel = standortFormLabel;

      if (standort.id !== undefined) {
        this.standortForm.controls['name'].setValue(this.standort.name);
        this.standortForm.controls['description'].setValue(this.standort.description);
        this.standortForm.controls['wlanId'].setValue(this.standort.wlanId);
        this.standortForm.controls['ssid'].setValue(this.standort.ssid);
        this.standortForm.controls['latitude'].setValue(this.standort.coordinates && this.standort.coordinates.length > 0 ? this.standort.coordinates[0].latitude : '');
        this.standortForm.controls['longitude'].setValue(this.standort.coordinates && this.standort.coordinates.length > 0 ? this.standort.coordinates[0].longitude : '');
        this.standortForm.controls['radius'].setValue(this.standort.coordinates && this.standort.coordinates.length > 0 ? this.standort.coordinates[0].radius : '');
      }
    });
  }

  onFormSubmit() {
    this.standort.name = this.standortForm.controls['name'].value;
    this.standort.description = this.standortForm.controls['description'].value;
    this.standort.wlanId = this.standortForm.controls['wlanId'].value;
    this.standort.ssid = this.standortForm.controls['ssid'].value;

    var latitude = this.standortForm.controls['latitude'].value;
    var longitude = this.standortForm.controls['longitude'].value;
    var radius = this.standortForm.controls['radius'].value;
    if (latitude && longitude && radius) {
      this.standort.coordinates = [
        new StandortCoordinatesModel({
          latitude: latitude,
          longitude: longitude,
          radius: radius
        })
      ];
    }
    else if (!latitude && !longitude) {
      this.standort.coordinates = [];
    }
    this.isSaving = true;
    this.SaveStandort();
  }

  SaveStandort() {
    if (this.standort.id !== undefined) {
      this.subscribeToSaveResponse(this.apiClient.putStandort(this.standort.id, this.standort));
    } else {
      this.subscribeToSaveResponse(this.apiClient.postStandort(this.standort));
    }
  }

  private subscribeToSaveResponse(result) {
    result.subscribe((res) => this.onSaveSuccess(), (res: HttpErrorResponse) => this.onSaveError());
  }

  private onSaveSuccess() {
    this.isSaving = false;
    this.previousState();
  }

  private onSaveError() {
    this.isSaving = false;
  }

  previousState() {
    window.history.back();
  }
}
