import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiClient, WorktimeAggregateModel, Standort, User, WorktimeEditModel, Project, WorktimeType, NameIdModel, VacationStatus, ChangeVacationStatusModel } from 'src/app/services/api.client';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { NumberHelper } from 'src/app/services/number-helper';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-worktime-details',
  templateUrl: './worktime-details.component.html',
  styleUrls: ['./worktime-details.component.css']
})
export class WorktimeDetailsComponent implements OnInit {
  form: FormGroup;
  statuses: string[];
  standorts: Standort[];
  users: User[];
  projects: Project[];
  maxDate = new Date(new Date().setDate(new Date().getDate() - 1));
  editMode: boolean;
  id: string;
  initialized: boolean;
  canEdit: boolean;
  details: WorktimeEditModel;
  types: WorktimeType[];
  availableProjects: Project[];

  constructor(private route: ActivatedRoute, private apiClient: ApiClient, private formbulider: FormBuilder) { }

  validateTimeSequence(self, c: FormControl) {
    if (self.form) {
      var start = self.getDateWithTime(self.form.controls.startDate && self.form.controls.startDate.value, self.form.controls.startTime && self.form.controls.startTime.value);
      var stop = self.getDateWithTime(self.form.controls.stopDate && self.form.controls.stopDate.value, self.form.controls.stopTime && self.form.controls.stopTime.value);
  
      if(stop <= start) {
        return {
          timeSequence: { valid: false }
        };
      }
    }

    return null;
  }

  ngOnInit() {
    this.form = this.formbulider.group({
      startDate: ['', [Validators.required]],
      startTime: ['', [Validators.required]],
      stopDate: ['', [Validators.required]],
      stopTime: ['', [Validators.required]],
      userId: ['', [Validators.required]],
      userName: [''],
      type: ['', [Validators.required]],
      projectId: ['', [Validators.required]]
    });

    this.form.controls.type.valueChanges.subscribe(val => {
      var controls = [
        this.form.controls.startTime,
        this.form.controls.stopTime,
        this.form.controls.projectId
      ];

      controls.forEach(c => {
        if (val == WorktimeType.Correction) {
          c.setValidators([Validators.required]);
        } else {
          c.clearValidators();
        }
        c.updateValueAndValidity();
      });
    });
    
    this.form.controls.userId.valueChanges.subscribe(val => {
      this.setAvailableProjects();
    });

    this.apiClient.getStandortAll().subscribe(s => {
      this.standorts =  s.sort((a, b) => a.name.localeCompare(b.name));
    });
    forkJoin(
      this.apiClient.getUsers(),
      this.apiClient.getProjects(true)
    ).subscribe(([users, projects2]) => {
      this.users = users.sort((a, b) => a['lastName'].localeCompare(b['lastName']));

      // Projects
      this.projects = projects2.sort((a, b) => a.name.localeCompare(b.name));
      this.setAvailableProjects();

      // Parameters
      this.route.queryParams.subscribe(p => {
        if (p['date']) {
          var date = this.getDate(new Date(parseInt(p['date'])));
          this.form.controls.startDate.setValue(date);
          this.form.controls.stopDate.setValue(date);
        }

        if (p['userId']) {
          this.form.controls.userId.setValue(parseInt(p['userId']));
        }
      });
      this.route.params.subscribe(p => {
        var id = p['id'];
        if (id && id !== 'new') {
          this.apiClient.getWorktime(id).subscribe(v => {

            this.id = id;
            this.canEdit = v.canEdit;

            this.details = v;

            this.form.controls.startDate.setValue(this.getDate(v.start));
            this.form.controls.startTime.setValue(this.getTimeString(v.start));
            this.form.controls.stopDate.setValue(this.getDate(v.stop ? v.stop : null));
            this.form.controls.stopTime.setValue(this.getTimeString(v.stop ? v.stop : null));
            this.form.controls.userId.setValue(v.userId);
            this.form.controls.type.setValue(v.type);
            this.form.controls.projectId.setValue(v.projectId);
            this.form.controls.userName.setValue(v.userName)

            this.initialized = true;

            this.setAvailableProjects();
          });

          this.editMode = true;
        } else {
          this.editMode = false;
          this.canEdit = true;
          this.initialized = true;
        }
      });
    });

    this.types = [
      WorktimeType.Correction,
      WorktimeType.BusinessTrip,
      WorktimeType.Flextime,
      WorktimeType.Holiday,
      WorktimeType.HomeOffice,
      WorktimeType.SickLaeve,
      WorktimeType.Vacation
    ];
  }

  isWorktime() {
    return this.form.controls.type.value == WorktimeType.Correction;
  }

  getDate(date: Date) {
    if (date) {
      return new Date(new Date(date).setHours(0, 0, 0, 0));
    }
  }

  getTimeString(date: Date) {
    if (date) {
      var hours = `0${date.getHours()}`;
      var minutes = `0${date.getMinutes()}`;

      return `${hours.slice(-2)}:${minutes.slice(-2)}`;
    }
  }

  previousState() {
    window.history.back();
  }

  onFormSubmit() {
    if (this.checkTimeSequence()) {
      this.apiClient.updateWorktime(this.details && this.details.id || 'new', new WorktimeEditModel({
        type: this.form.controls.type.value,
        projectId: this.form.controls.projectId.value || 0,
        start: this.getDateWithTime(this.form.controls.startDate.value, this.form.controls.startTime.value) || this.form.controls.startDate.value,
        stop: this.getDateWithTime(this.form.controls.stopDate.value, this.form.controls.stopTime.value) || this.form.controls.stopDate.value,
        userId: this.form.controls.userId.value
      })).subscribe(() => this.previousState());
    }
  }
  
  getDateWithTime(date: Date, time: string): Date {
    if(moment.isMoment(date)){
      date = date.toDate();
    }
    if (time) {
      var hours = NumberHelper.Parse(time.split(':'), 0);
      var minutes = NumberHelper.Parse(time.split(':'), 1);
  
      return new Date(new Date(date).setHours(hours, minutes, 0, 0));
    }
  }

  checkTimeSequence() {
    if (this.form.controls.type.value !== WorktimeType.Correction) {
      return this.form.controls.startDate.value && this.form.controls.stopDate.value &&
             moment(this.form.controls.startDate.value) <= moment(this.form.controls.stopDate.value);
    }
    return this.validateTimeSequence(this, null) == null;
  }

  getMaxDateForStop() {
    if (this.form.controls.type.value !== WorktimeType.Correction) {
      return null;
    }
    return this.form.controls.startDate.value || this.maxDate;
  }

  getMaxDateForStart() {
    if (this.form.controls.type.value !== WorktimeType.Correction) {
      return null;
    }
    return this.maxDate;
  }

  setAvailableProjects() {
    var userId = this.form.controls.userId.value;
    if(!userId) {
      return [];
    }
    if(!this.projects) {
      return [];
    }

    if (this.form.controls.startDate != null)
      this.projects = this.projects.filter(p => p.endDate >= this.form.controls.startDate.value);

    var projects = this.projects.filter(p => {
      return p.id == 0 || p.projectAssignment != null && p.projectAssignment.find(pa => {
        return pa.userId == userId;
      });
    });

    if(this.details != null && this.details.projectId != null) {
      var hasSaved = projects.findIndex(p => {
        return p.id == this.details.projectId;
      }) != -1;

      if (!hasSaved) {
        projects.push(new Project({id:this.details.projectId, name:this.details.projectName}));
      }
    }

    var hasSelected = projects.findIndex(p => {
      return p.id == this.form.controls.projectId.value
    }) != -1;

    if(!hasSelected) {
      this.form.controls.projectId.setValue(0);
    }

    this.availableProjects = projects.sort((a, b) => a.name.localeCompare(b.name));
  }

  canChangeAbsenceStatus() {
    return this.details && this.details.absenceStatus && this.details.absenceStatus !== VacationStatus.Approved && this.details.absenceStatus !== VacationStatus.Rejected;
  }

  approve() {
    this.apiClient.changeVacationStatus(this.details.absenceId, new ChangeVacationStatusModel({ status: VacationStatus.Approved })).subscribe(r => {
      this.previousState();
    });
  }

  reject() {
    this.apiClient.changeVacationStatus(this.details.absenceId, new ChangeVacationStatusModel({ status: VacationStatus.Rejected })).subscribe(r => {
      this.previousState();
    });
  }
}
