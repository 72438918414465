import { Component, OnInit } from '@angular/core';
import { AuthUserService } from '../../services/auth-user/auth-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthManager, SignInType, SignInOptions } from '../../services/auth-manager';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  errMsg = "";
  currentSignInState: any;

  constructor(private authManager: AuthManager, private authUserService: AuthUserService, private router: Router, private route: ActivatedRoute) {
    this.currentSignInState = this.authManager.getCurrentSignInState();
  }

  signIn(type: string): void {
    this.authManager.signInRedirect(new SignInOptions({type: SignInType[type]}));
  }

  completeSignIn(id_token, loginType) {
    this.authUserService.signIn(id_token, loginType).then(() => {
      this.router.navigate(['/']);
    }).catch(reason => {
      this.errMsg = "ERROR.COMMON";
    });
  }

  processSignInCallback() {
    if(this.currentSignInState && this.currentSignInState.id_token) {
      this.completeSignIn(this.currentSignInState.id_token, this.currentSignInState.options.type);
      return true;
    }
    return false;
  }

  ngOnInit() {
    if (this.processSignInCallback()) {
      return;
    }

    if (this.authUserService.isSignedIn()) {
      this.router.navigate(['/']);
      return;
    }
  }
}
