import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ApiClient, CorrectionRequestCreateModel,
  IWorktimeAggregateModel,
  Project,
  User,
  VacationType,
  WorktimeAggregateModel, WorktimeEditModel,
  WorktimeMassEditModel, WorktimeWarningModel
} from 'src/app/services/api.client';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ConfirmationDialogService} from 'src/app/services/comfirmation-dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {Params, Router} from '@angular/router';
import * as moment from 'moment';
import {LanguageConfigurator} from 'src/app/services/translation';
import {TranslateService} from '@ngx-translate/core';
import {AuthUser, AuthUserService} from 'src/app/services/auth-user/auth-user.service';
import {FilterService, FilterServiceModel} from 'src/app/services/filter-service';
import {getDisplayName} from 'src/app/shared';
import {WorktimeDetailsQuickEditDialog} from '../worktime-approval-list/worktime-details-quick-details-dialog/worktime-details-quick-details-dialog';
import {AddEntryDialogComponent} from '../add-entry-dialog/add-entry-dialog.component';
import {ChangeProjectDialogComponent} from '../change-project-dialog/change-project-dialog.component';
import {MatSelectChange} from '@angular/material';
import {ProjectDuration} from '../project-hours/project-hours.component';
import {cloneDeep} from 'lodash';
import {FormControl, Validators} from '@angular/forms';
import {ErrorMesageService} from "src/app/services/error-message-service";

export interface ProjectWithTime {
  projectName: string
  spentMinutes: number
}

@Component({
  selector: 'app-worktime-list',
  templateUrl: './worktime-list.component.html',
  styleUrls: ['./worktime-list.component.scss']
})
export class WorktimeListComponent implements OnInit, OnDestroy {
  worktimes = new MatTableDataSource<WorktimeAggregateModel>();
  displayedColumns: string[] = ['standortName', 'start', 'stop', 'duration', 'project', 'actions'];
  editingElement: any;
  from = moment().startOf('month');
  to = moment();
  date: any;
  userId: number;
  user: User;
  authUser: AuthUser;
  canEditSelf: boolean;
  totalDuration: string
  errorText: string
  pauseDuration: string
  isWarning: boolean
  isHoursWarning: boolean
  isPauseWarning: boolean
  isAlreadySavedCorrectionRequest = false;
  isMonthClosed: boolean
  warnings: WorktimeWarningModel[]
  durationByProject: ProjectDuration[]
  projects: Project[]
  filterModel: FilterServiceModel;
  commentControl = new FormControl('', Validators.required)

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;
  @HostListener('document:keyup.enter')
  onEnterHandler() {
    this.toggleRowForm(this.editingElement)
    this.editingElement = null
  }

  @HostListener('document:keyup.esc')
  onEscHandler() {
    this.toggleRowForm(this.editingElement)
    this.editingElement = null
  }

  constructor(private apiClient: ApiClient, private confirmService: ConfirmationDialogService, public dialog: MatDialog, private router: Router,
              private languageConfigurator: LanguageConfigurator, private translateService: TranslateService, private authUserService: AuthUserService, filterService: FilterService,
              private errorMessageService: ErrorMesageService) {
    this.filterModel = filterService.createFilter(this.worktimes, (p) => this.validateFilterValues(p), () => this.applyFilter());
    this.worktimes.filterPredicate = (data, filter) => {
      return filterService.filterTableData([
        data['userName'],
        data['standortName'],
        data['projectName'],
        translateService.instant(`ABSENCE.STATUSES.${data['status']}`)
      ], filter);
    };
  }

  ngOnDestroy(): void {
    this.filterModel.destroy();
  }

  get displayName() {
    return getDisplayName(this.user);
  }

  get currentTime() {
    return moment().format('HH:mm')
  }

  toggleRowForm(row: any) {
    if (row.absence) {
      return
    }
    if (this.editingElement && this.editingElement !== row) {
      this.editingElement.isEditableStateActive = false;
    }
    this.editingElement = row;
    row.isEditableStateActive = !row.isEditableStateActive;
    if (!row.isEditableStateActive) {
      this.validateWTimesFromServer()
    }
  }

  titleDetails() {
    if (this.date == undefined || this.user == undefined) {
      return null;
    }
    return '' + moment(this.date).format('YYYY-MM-DD');
  }

  isValidDate(d) {
    return d && d.isValid && d.isValid();
  }

  validateFilterValues(params: Params) {
    this.from = moment(params['from']);
    if (!this.isValidDate(this.from)) {
      this.from = moment();
    }
    this.to = moment(params['to']);
    if (!this.isValidDate(this.to)) {
      this.to = moment();
    }

    this.date = params['date'] && moment(params['date']);
    if (!this.isValidDate(this.date)) {
      this.date = undefined;
    }

    if (this.date) {
      this.from = this.date;
      this.to = this.date;
    }

    if (parseInt(params['userId'])) {
      this.userId = parseInt(params['userId']);
      this.apiClient.getUser(this.userId).subscribe(user => {
        this.user = user;
      });
    }
  }

  getDailySummary() {
    this.apiClient.getDailySummary(this.date, this.userId, this.translateService.currentLang).subscribe(sum => {
      this.parseDurationByProj(sum)
      this.totalDuration = sum.totalDuration
      this.pauseDuration = sum.pauseDuration
      this.isWarning = sum.isWarning
      this.errorText = sum.comment
      this.warnings = sum.warnings
      this.isPauseWarning = sum.warnings.some(warning => warning.code === 'WORKTIME.MONTHLY_WARNINGS.MINIMUM_BREAK_TIME_NOT_REACHED');
      this.isHoursWarning = sum.warnings.some(warning => warning.code === 'WORKTIME.MONTHLY_WARNINGS.MAXIMUM_WORKTIME_EXCEEDED');
    })
  }

  ngOnInit() {
    this.worktimes.paginator = this.paginator;
    this.worktimes.sort = this.sort;
    this.filterModel.loadFilterValuesAndApply();
    this.authUser = this.authUserService.getUser();
    this.apiClient.getUserCanEditSelf().subscribe(canEditSelf => {
      this.canEditSelf = canEditSelf;
    });

    this.apiClient.checkIsMonthApproved(this.userId, this.date).subscribe(isMonthClosed => {
      this.isMonthClosed = isMonthClosed;
    });

    if (this.isCorrectionRequest()) {
      this.apiClient.getDetails(this.date, this.userId).subscribe(res => {
        this.commentControl.patchValue(res.requestComment)
        this.isAlreadySavedCorrectionRequest = true;
      }, e => {
        if (e.status !== 404) {
          throw e;
        }
        this.getDailySummary()
        this.isAlreadySavedCorrectionRequest = false;
      })
    } else {
      this.getDailySummary()
      this.isAlreadySavedCorrectionRequest = false;
    }

    this.apiClient.getProjects(null).subscribe(projs => {
        this.projects = projs.filter(p => {
          return p.id == 0 || p.projectAssignment != null && p.projectAssignment.find(pa => {
            return pa.userId == this.userId;
          });
        });
      }
    )
  }

  isAddFunctionAvailable() {
    return this.date.toDate().setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)
  }

  parseDurationByProj(summary) {
    this.durationByProject = []
    // tslint:disable-next-line:forin
    for (const key in summary.durationByProjects) {
      const projDur: ProjectDuration = {
        name: key.toString(),
        duration: summary.durationByProjects[key]
      }
      this.durationByProject.push(projDur)
    }
  }

  filter() {
    var params = {};
    if (this.from) {
      params['from'] = this.from.format('YYYY-MM-DD');
    }
    if (this.to) {
      params['to'] = this.to.format('YYYY-MM-DD');
    }
    this.filterModel.applyFilter(params);
  }

  applyQuickFilter(filterValue: string) {
    this.filterModel.applyQuickFilter(filterValue);
  }

  applyFilter() {
    this.apiClient.getWorktimes(this.from.toDate(), this.to.toDate(), this.userId, true).subscribe(v => {
      v.forEach(item => {
        let absence = item.details.absence && item.details.absence.wholeDay;
        item['absence'] = absence;
        item['userName'] = item.user.name;
        item['standortName'] = !item.details.absence ? (item.standort.id === 0 ? 'ABSENCE.TYPES.' + item.standort.name : item.standort.name) : 'ABSENCE.TYPES.' + item.details.absence.type;
        item['status'] = item.details.absence ? item.details.absence.status : null;
        item['projectName'] = !absence ? item.project.name : '';
        item['start'] = !absence ? item.details.start && item.details.start.time : item.details.absence.start;
        item['stop'] = !absence ? item.details.stop && item.details.stop.time : item.details.absence.end;
        item['startApp'] = !absence ? item.details.start.app : '';
        item['stopApp'] = !absence ? item.details.stop && item.details.stop.app : '';
        item['isEditableStateActive'] = false;
        item['invalidRange'] = false;
        item['isStopRequired'] = !!item.details.stop
      });
      this.worktimes.data = v;

      if (this.isCorrectionRequest()) {
        this.validateWTimesFromServer()
      }
    });
  }

  delete(id: string, idx: number) {
    let confirm = 'WORKTIME.DELETE_CONFIRM';
    let parameters = {};
    const worktime = this.worktimes.data[idx];
    if (worktime.details.absence) {
      confirm = 'WORKTIME.DELETE_ABSENCE_CONFIRM';
    }
    this.confirmService.translateAndOpen(confirm, parameters).subscribe(r => {
      if (r) {
        const wTimes = this.worktimes.data
        wTimes.splice(idx, 1)

        //if absence is deleted, delete all worktimes linked to the absence
        if (worktime.details.absence) {
          var relatedWorktimes = wTimes.filter(wt => wt.standort.id === 0);
          relatedWorktimes.forEach(wt => {
            wTimes.splice(wTimes.indexOf(wt), 1)
          })
        }

        this.worktimes.data = wTimes
        this.validateWTimesFromServer()
      }
    });
  }

  getDuration(miliseconds) {
    var date = new Date(miliseconds);

    var seconds = date.getUTCSeconds();
    var minutes = date.getUTCMinutes();
    var hours = date.getUTCHours();

    var result = '';
    if (seconds > 0) {
      result = `${seconds}s`;
    }
    if (minutes > 0 || hours > 0) {
      result = `${minutes}m ` + result;
    }
    if (hours > 0) {
      result = `${hours}h ` + result;
    }
    return result;
  }

  previousState() {
    window.history.back();
  }

  checkCanEdit() {
    if (this.authUser.role === 'SUPER_ADMIN' || this.authUser.role === 'USER' || this.authUser.role === 'STANDORT_ADMIN' || this.authUser.role === 'WORKTIME_ADMIN' && !this.canEditSelf) {
      return true;
    } else if (this.authUser.role === 'WORKTIME_ADMIN') {
      if (this.userId === this.authUser.userId) {
        return this.canEditSelf;
      }
      return true;
    }
    return false;
  }

  splitWorkTime(element: any) {
    const dialogRef = this.dialog.open(
      WorktimeDetailsQuickEditDialog,
      {
        data: {
          type: 'Split',
          userId: this.userId,
          start: `${element.start.getHours()}:${element.start.getMinutes() < 10 ? '0' + element.start.getMinutes() : element.start.getMinutes()}`,
          stop: `${element.stop.getHours()}:${element.stop.getMinutes() < 10 ? '0' + element.stop.getMinutes() : element.stop.getMinutes()}`,
          date: `${element.start.getFullYear()}-${element.start.getMonth() + 1}-${element.start.getDate()}`
        }
      }
    );

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        const startTime = new Date(this.date)
        startTime.setHours(+res.pauseStart.slice(0, 2))
        startTime.setMinutes(+res.pauseStart.slice(-2))
        const stopTime = new Date(this.date)
        stopTime.setHours(+res.pauseStop.slice(0, 2))
        stopTime.setMinutes(+res.pauseStop.slice(-2))

        const details = {
          absence: undefined,
          start: {
            app: 'WebAdmin',
            forcedPause: false,
            id: null,
            latitude: 0,
            longitude: 0,
            modifiedUserId: this.authUser.userId,
            modifiedUserName: this.authUser.username,
            projectId: element.project.id,
            time: startTime,
            type: 'WebAdmin'
          },
          stop: {
            app: 'WebAdmin',
            forcedPause: false,
            id: null,
            latitude: 0,
            longitude: 0,
            modifiedUserId: this.authUser.userId,
            modifiedUserName: this.authUser.username,
            projectId: element.project.id,
            time: new Date(element.stop.getTime()),
            type: 'WebAdmin'
          }
        }
        const newWorktime = {
          id: null,
          canEdit: true,
          absence: undefined,
          isEditableStateActive: false,
          invalidRange: false,
          organizationId: null,
          standort: {id: 0, name: 'Correction'},
          standortName: 'ABSENCE.TYPES.Correction',
          projectName: element.project.name,
          project: cloneDeep(element.project),
          start: stopTime,
          stop: new Date(element.stop.getTime()),
          details: details,
          isStopRequired: true
        }
        element.stop = new Date(startTime.getTime())
        let data = this.worktimes.data
        // @ts-ignore
        data.push(newWorktime)
        // @ts-ignore
        data = data.sort((prev, next) => {
          // @ts-ignore
          if (prev.details.absence != null && next.details.absence == null) {
            return 1
          }
          // @ts-ignore
          else if (prev.details.absence == null && next.details.absence != null) {
            return -1
          }
          // @ts-ignore
          else if (prev.details.absence == null && next.details.absence == null) {
            // @ts-ignore
            if (!!prev.details.absence || this.getMinutesFromDate(prev.start) > this.getMinutesFromDate(next.start)) {
              return 1
            }
            // @ts-ignore
            if (!prev.details.absence || this.getMinutesFromDate(prev.start) < this.getMinutesFromDate(next.start)) {
              return -1
            }
          }
          return 0
        })
        this.worktimes.data = data
        this.validateAllTimeRanges()
        this.validateWTimesFromServer()
        this.table.renderRows()
      }
    })
  }

  editStart(newValue: string, worktime: any) {
    const hours = Number(newValue.split(':')[0])
    const minutes = Number(newValue.split(':')[1])
    const dateClone = new Date(this.date)
    dateClone.setHours(hours)
    dateClone.setMinutes(minutes)
    dateClone.setSeconds(0)
    worktime.start = dateClone
    this.validateAllTimeRanges()
  }

  editStop(newValue: string, worktime: any) {
    if (newValue === null) {
      worktime.stop = null
      worktime.details.stop = null
      worktime.invalidRange = worktime.isStopRequired
      return
    }
    const hours = Number(newValue.split(':')[0])
    const minutes = Number(newValue.split(':')[1])
    const dateClone = new Date(this.date)
    dateClone.setHours(hours)
    dateClone.setMinutes(minutes)
    dateClone.setSeconds(0)
    worktime.stop = dateClone
    if (!worktime.details.stop) {
      worktime.details.stop = {
        app: 'WebAdmin',
        forcedPause: false,
        id: null,
        latitude: 0,
        longitude: 0,
        modifiedUserId: this.authUser.userId,
        modifiedUserName: this.authUser.username,
        projectId: worktime.project.id,
        time: dateClone,
        type: 'WebAdmin'
      }
    }
    this.validateAllTimeRanges()
  }

  editProject(newValue: MatSelectChange, workTime: any) {
    const proj = this.projects.find(project => project.id === newValue.value)
    workTime.projectName = proj.name
    workTime.project.id = proj.id
    workTime.project.name = proj.name
  }

  validateAllTimeRanges() {
    this.worktimes.data.forEach(worktime => {
      if (!worktime.details.absence) {
        this.validateTimeRangeInRow(worktime)
      }
    })
  }

  validateTimeRangeInRow(worktime: any) {
    const currentDate = new Date()
    const dayDate = new Date(this.date)

    worktime.stop.setSeconds(0)
    worktime.stop.setMilliseconds(0)
    worktime.stop.setFullYear(dayDate.getFullYear())
    worktime.stop.setMonth(dayDate.getMonth())
    worktime.stop.setDate(dayDate.getDate())

    worktime.start.setSeconds(0)
    worktime.start.setMilliseconds(0)
    worktime.start.setFullYear(dayDate.getFullYear())
    worktime.start.setMonth(dayDate.getMonth())
    worktime.start.setDate(dayDate.getDate())

    const isValidWithCurrentDate = worktime.stop <= currentDate && worktime.start <= currentDate
    const isValidWithSiblings = this.validateSiblingTimeRange(worktime)
    if (this.getMinutesFromDate(worktime.stop) > this.getMinutesFromDate(worktime.start) && isValidWithCurrentDate && isValidWithSiblings) {
      worktime.invalidRange = false
    } else {
      worktime.invalidRange = true
    }
  }

  validateSiblingTimeRange(worktime: any) {
    for(var i = 0; i < this.worktimes.data.length; i++) {
      if (this.worktimes.data[i] === worktime) {
        var previous = i > 0 ? this.worktimes.data[i - 1] as any : null;
        if (previous != null && !previous.details.absence && this.getMinutesFromDate(previous.stop) > this.getMinutesFromDate(worktime.start)) {
          return false;
        }

        var next = i < this.worktimes.data.length - 1 ? this.worktimes.data[i + 1] as any : null;
        if (next != null && !next.details.absence && this.getMinutesFromDate(next.start) < this.getMinutesFromDate(worktime.stop)) {
          return false;
        }

        return true;
      }
    }
    return true;
  }

  openAddEntryDialog() {
    const dialogRef = this.dialog.open(
      AddEntryDialogComponent, {width: '480px', height: 'fit-content', data: {userId: this.userId, date: this.date}}
    )

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        const startTime = new Date(this.date)
        startTime.setHours(+res.start.slice(0, 2))
        startTime.setMinutes(+res.start.slice(-2))
        startTime.setSeconds(0)
        const stopTime = new Date(this.date)
        stopTime.setHours(+res.end.slice(0, 2))
        stopTime.setMinutes(+res.end.slice(-2))
        stopTime.setSeconds(0)
        const details = {
          absence: undefined,
          start: {
            app: 'WebAdmin',
            forcedPause: false,
            id: null,
            latitude: 0,
            longitude: 0,
            modifiedUserId: this.authUser.userId,
            modifiedUserName: this.authUser.username,
            projectId: res.project.id,
            time: startTime,
            type: 'WebAdmin'
          },
          stop: {
            app: 'WebAdmin',
            forcedPause: false,
            id: null,
            latitude: 0,
            longitude: 0,
            modifiedUserId: this.authUser.userId,
            modifiedUserName: this.authUser.username,
            projectId: res.project.id,
            time: stopTime,
            type: 'WebAdmin'
          }
        }
        const newWorktime = {
          id: null,
          canEdit: true,
          absence: undefined,
          isEditableStateActive: false,
          invalidRange: false,
          organizationId: null,
          standort: {id: 0, name: 'Correction'},
          standortName: 'ABSENCE.TYPES.Correction',
          projectName: res['project'].name,
          project: res['project'],
          start: startTime,
          stop: stopTime,
          details: details,
          isStopRequired: true
        }
        let data = this.worktimes.data

        if (data && data.length > 0) {
          const lastItem = data[data.length - 1];
          if (lastItem.details.stop == null &&
            (lastItem.details.absence == null || (lastItem.details.absence.type != VacationType.Flextime && lastItem.details.absence.type != VacationType.HomeOffice && lastItem.details.absence.type != VacationType.BusinessTrip))
            && this.getMinutesFromDate(lastItem.details.start.time) < this.getMinutesFromDate(details.stop.time)) {
              this.errorMessageService.showError('ERROR.ALREADY_EXIST_WITH_GIVEN_START_STOP')
              return;
            }
        }
        // @ts-ignore
        data.push(newWorktime)
        // @ts-ignore
        data = data.sort((prev, next) => {
          // @ts-ignore
          if (prev.details.absence != null && next.details.absence == null) {
            return 1
          }
          // @ts-ignore
          else if (prev.details.absence == null && next.details.absence != null) {
            return -1
          }
          // @ts-ignore
          else if (prev.details.absence == null && next.details.absence == null) {
            // @ts-ignore
            if (!!prev.details.absence || this.getMinutesFromDate(prev.start) > this.getMinutesFromDate(next.start)) {
              return 1
            }
            // @ts-ignore
            if (!prev.details.absence || this.getMinutesFromDate(prev.start) < this.getMinutesFromDate(next.start)) {
              return -1
            }
          }
          return 0
        })
        this.worktimes.data = data
        this.validateAllTimeRanges()
        this.validateWTimesFromServer()
        this.table.renderRows()
      }
    })
  }

  changeProject(element) {
    const dialogRef = this.dialog.open(ChangeProjectDialogComponent,
      {width: '530px', height: 'fit-content', data: {start: element.start, stop: element.stop, userId: this.userId}}
    )
    dialogRef.afterClosed().subscribe(res => {
      const startTime = new Date(this.date);
      startTime.setHours(+res.time.slice(0, 2))
      startTime.setMinutes(+res.time.slice(-2))
      startTime.setSeconds(0)
      const newDetails = cloneDeep(element['details'])
      newDetails.start.time = startTime
      newDetails.stop.time = new Date(element['stop'].getTime())
      const newWorktime = {
        id: null,
        canEdit: true,
        absence: undefined,
        isEditableStateActive: false,
        organizationId: element.organizationId,
        standort: cloneDeep(element.standort),
        standortName: element.standortName,
        projectName: res['project'].name,
        project: res['project'],
        start: startTime,
        stop: new Date(element['stop'].getTime()),
        details: newDetails
      }
      element['stop'] = new Date(startTime.getTime())
      const data = this.worktimes.data
      // @ts-ignore
      data.push(newWorktime)
      // @ts-ignore
      data = data.sort((prev, next) => {
        // @ts-ignore
        if (prev.details.absence != null && next.details.absence == null) {
          return 1
        }
        // @ts-ignore
        else if (prev.details.absence == null && next.details.absence != null) {
          return -1
        }
        // @ts-ignore
        else if (prev.details.absence == null && next.details.absence == null) {
          // @ts-ignore
          if (!!prev.details.absence || this.getMinutesFromDate(prev.start) > this.getMinutesFromDate(next.start)) {
            return 1
          }
          // @ts-ignore
          if (!prev.details.absence || this.getMinutesFromDate(prev.start) < this.getMinutesFromDate(next.start)) {
            return -1
          }
        }
        return 0
      })
      this.worktimes.data = data
      this.validateWTimesFromServer()
    })
  }

  isPause(worktime: any, idx: number) {
    if (idx === 0) {
      return false
    }
    if (idx !== 0 || idx) {
      let duration = 0
      const prevWorkTime = this.worktimes.data[idx - 1]
      // @ts-ignore
      if (this.getMinutesFromDate(worktime.start) > this.getMinutesFromDate(prevWorkTime.stop)) {
        // @ts-ignore
        const minutesStart = this.getMinutesFromDate(prevWorkTime.stop);
        // @ts-ignore
        const minutesStop =  this.getMinutesFromDate(worktime.start)
        duration = minutesStop - minutesStart
      }
      const mins = duration % 60 < 10 ? '0' + duration % 60 : duration % 60
      const hours = Math.trunc(duration / 60) < 10 ? '0' + Math.trunc(duration / 60) : Math.trunc(duration / 60)
      return duration !== 0 && duration > 0 ? `${hours} : ${mins}` : false
    }
  }

  getMinutesFromDate(date: Date) {
    return date.getHours() * 60 + date.getMinutes()
  }

  isWorktimesValid() {
    return this.worktimes.data.some((wt: any) => wt.invalidRange)
  }

  getPreparedWTime(worktime: any) {
    return {
      'id': worktime.id,
      'standortId': worktime.standort ? worktime.standort.id : null,
      'standortName': worktime.standort ? worktime.standort.name : null,
      'userId': this.userId,
      'userName': this.user ? this.user.name : null,
      'start': worktime.start,
      'stop': worktime.stop,
      'projectId': worktime.project ? worktime.project.id : null,
      'projectName': worktime.project ? worktime.project.name : null,
      'type': worktime.details.absence ? worktime.details.absence.type : 'Correction',
      'absenceStatus': worktime.details.absence ? worktime.details.absence.status : null,
      'absenceId' : worktime.details.absence ? worktime.details.absence.id : 0,
      'startModifiedUser': {
        'id': worktime.details.start.modifiedUserId,
        'name': worktime.details.start.modifiedUserName
      },
      'endModifiedUser': worktime.details.stop
        ? {
          'id': worktime.details.stop.modifiedUserId,
          'name': worktime.details.stop.modifiedUserName
        }
        : null
    }
  }

  validateWTimesFromServer() {
    if (!this.isWorktimesValid()) {
      const wTimeWRows = []
      this.worktimes.data.forEach(wTime => {
          const wTimeRow = this.getPreparedWTime(wTime)
          wTimeWRows.push(wTimeRow)
        }
      )
      const validationData = {
        'userId': this.userId,
        'date': this.date,
        'rows': wTimeWRows
      }

      this.apiClient.calculateWarnings(this.translateService.currentLang, validationData as WorktimeMassEditModel).subscribe(res => {
        const {pauseDuration, totalDuration, warnings} = res
        this.pauseDuration = pauseDuration
        this.totalDuration = totalDuration
        this.errorText = warnings.length > 0 ? warnings.reduce((pv, c) => pv + `\n${c.message}`, '') : ''
        this.isWarning = this.errorText != ''
        this.isPauseWarning = warnings.some(warning => warning.code === 'WORKTIME.MONTHLY_WARNINGS.MINIMUM_BREAK_TIME_NOT_REACHED');
        this.isHoursWarning = warnings.some(warning => warning.code === 'WORKTIME.MONTHLY_WARNINGS.MAXIMUM_WORKTIME_EXCEEDED');
        this.parseDurationByProj(res)
      })
    }
  }

  pushWorktimesToServer() {
    if (!this.isWorktimesValid()) {
      const wTimeWRows = []
      this.worktimes.data.forEach(wTime => {
          const wTimeRow = this.getPreparedWTime(wTime)
          wTimeWRows.push(wTimeRow)
        }
      )
      const newData = {
        'userId': this.userId,
        'date': this.date,
        'rows': wTimeWRows
      }
      if (this.isCorrectionRequest()) {
        this.apiClient.save(
          this.date, this.userId, {rows: wTimeWRows, requestComment: this.commentControl.value} as CorrectionRequestCreateModel).subscribe(v => {
            this.previousState()
          })
      } else {
        this.apiClient.editWorktimesForDate(this.date, this.userId, newData as WorktimeMassEditModel).subscribe(v => {
          this.previousState()
        })
      }
    }
  }

  edit(element: any) {
    this.toggleRowForm(element)
  }

  isCorrectionRequest() {
    return this.authUser.role === 'USER' || this.authUser.role === 'STANDORT_ADMIN'
      || (this.authUser.role === 'WORKTIME_ADMIN' && this.authUser.userId === this.userId && !this.canEditSelf);
  }

  checkCanDeleteCorrectionRequest() {
    return this.isCorrectionRequest && this.isAlreadySavedCorrectionRequest;
  }

  deleteRequest() {
    this.apiClient.rejectChanges(this.date, this.user.id).subscribe(() => this.previousState())
  }
}
