export class DateHelper {
    static getDatePart(date: Date) {
        if (!date) {
            return date;
        }
        return new Date(new Date(date).setHours(0, 0, 0, 0));
    }
}

export const MY_FORMATS = {
    parse: {
      dateInput: 'LL',
    },
    display: {
      dateInput: 'YYYY-MM-DD',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY',
    },
  };