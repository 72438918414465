import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {timeRangeValidator, validateWithCurrentTime} from '../../../shared';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ApiClient, Project} from '../../../services/api.client';
import {map} from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-add-entry-dialog',
  templateUrl: './add-entry-dialog.component.html',
  styleUrls: ['./add-entry-dialog.component.css']
})
export class AddEntryDialogComponent implements OnInit {
  entryForm: FormGroup;
  projects$: Observable<Project[]>

  constructor(
    private readonly fb: FormBuilder,
    public readonly dialogRef: MatDialogRef<AddEntryDialogComponent>,
    private readonly apiClient: ApiClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  private initForm() {
    this.entryForm = this.fb.group({
      date: [new Date(this.data.date)],
      start: [null, Validators.required],
      end: [null, Validators.required],
      project: [null, Validators.required]
    }, {
      validators: [timeRangeValidator(), validateWithCurrentTime()]
    })
  }

  ngOnInit() {
    this.initForm()
    this.projects$ = this.apiClient.getProjects(false).pipe(
      map(projs => {
        return projs.filter(p => {
          return p.id == 0 || p.projectAssignment != null && p.projectAssignment.find(pa => {
            return pa.userId == this.data.userId;
          });
        });
      })
    )
  }

  save() {
    const {start, stop} = this.entryForm.value
    const currentTime = moment()
    if (start > currentTime || stop > currentTime) {
      // @ts-ignore
      this.entryForm.setErrors({'incorrectTime': true})
    }
    const result = {...this.entryForm.value}
    this.dialogRef.close(result)
  }

}
