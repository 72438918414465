import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "src/app/modules/project/project-detail/project-detail.component";
import { ApiClient } from "src/app/services/api.client";

@Component({
    selector: 'user-select-dialog',
    templateUrl: './user-select-dialog.component.html',
    styleUrls: ['./user-select-dialog.component.css']
  })
  export class UserSelectDialog {
    users: any;
    initialized: boolean;
    selectedUsers = {};
    filteredItems : any[];
    selectAllChecked: boolean;
  
    constructor(
      public dialogRef: MatDialogRef<UserSelectDialog>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      public apiClient: ApiClient
    ) {
      this.filteredItems = data.users;
      var selectedUsers = data.selectedUserId || [];
      data.users.forEach(item => {
        this.selectedUsers[item.id] = selectedUsers.indexOf(item.id) !== -1;
      });
    }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
    getSelectedOptions(selected) {
      this.data.selectedUserId = selected;
    }
  
    toggleSelectAll(val) {
      this.filteredItems.forEach(item => {
        this.selectedUsers[item.id] = val.checked;
      });
    }
  
    onSelectionChange(val) {
      this.filterItems(val);
      this.checkSelectedAll();
    }
  
    checkSelectedAll() {
      var checked = this.filteredItems.filter(item => this.selectedUsers[item.id] === true).length;
      this.selectAllChecked = checked === this.filteredItems.length;
    }
  
    filterItems(val) {
      this.filteredItems = this.data.users.filter(item => {
        return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1
      });
      this.checkSelectedAll();
    }
  
    getSelectedUserIds() {
      var result = [];
      this.data.users.forEach(user => {
        if (this.selectedUsers[user.id] === true) {
          result.push(user.id.toString());
        }
      });
      return result;
    }
  }
  