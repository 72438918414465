import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthUserService } from './services/auth-user/auth-user.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authUserService: AuthUserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authUserService.isSignedIn()) {
      this.router.navigate(['/login']);
      return false;
    }

    var user = this.authUserService.getUser();
    if (route.data != null && route.data.roles != null && route.data.roles.indexOf(user.role) == -1) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}