import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthManager, SignInOptions } from 'src/app/services/auth-manager';
import { ErrorMesageService } from 'src/app/services/error-message-service';

@Component({
  selector: 'app-sign-in-callback',
  templateUrl: './sign-in-callback.component.html',
  styleUrls: ['./sign-in-callback.component.css']
})
export class SignInCallbackComponent implements OnInit {
  error: string;

  constructor(private activateRoute: ActivatedRoute, private router: Router, private errorMessage: ErrorMesageService, private authManager: AuthManager) { }

  ngOnInit() {
    var params = this.authManager.getSignInParams();
    if (params == null) {
      this.showErrorAndReturn(null);
      return;
    }

    this.activateRoute.fragment.subscribe(fragment => {
      var urlSearchParams = new URLSearchParams(fragment);

      var id_token = urlSearchParams.get('id_token');
      var state = urlSearchParams.get('state');
      if (id_token && params.state === state) {
        this.completeSignIn(id_token, params.options);
        return;
      }
      var error = '';
      if(!id_token) {
        error = 'ERROR.TOKEN_IS_EMPTY';
      }
      if(params.state !== state) {
        error = "ERROR.INVALID_REQUEST_OR_SESSION_HAS_EXPIRED";
      }
      this.showErrorAndReturn(params.options.returnUrl, error);
    });
  }

  completeSignIn(id_token: string, options: SignInOptions) {
    this.router.navigate([options.returnUrl], { state: { id_token: id_token, options: options } });
  }

  showErrorAndReturn(returnRoute: string, message?:string) {
    this.error = message || 'ERROR.INVALID_REQUEST_OR_SESSION_HAS_EXPIRED';
    console.error(this.error);

    setTimeout(() => {
      this.router.navigate([returnRoute || '/']);
    }, 2000);
  }
}
