import { Component, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IProject, ApiClient } from '../../../services/api.client';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router, Params } from '@angular/router';
import { ConfirmationDialogService } from '../../../services/comfirmation-dialog.service';
import { AuthUserService } from 'src/app/services/auth-user/auth-user.service';
import { TranslateService, TranslateParser } from '@ngx-translate/core';
import { SettingsProviderService } from 'src/app/services/settings-provider-service';
import { FilterService, FilterServiceModel } from 'src/app/services/filter-service';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.css']
})
/** project-list component*/
export class ProjectListComponent implements OnDestroy {
  projectDS = new MatTableDataSource<IProject>();
  displayedColumns: string[] = ['name', 'description', 'start', 'end', 'actions'];
  filterModel: FilterServiceModel;
  showOnlyActive: boolean = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  canDelete: boolean;

  constructor(private router: Router, private apiClient: ApiClient, private confirmService: ConfirmationDialogService,
    private userService: AuthUserService, private settingProvider: SettingsProviderService, filterService: FilterService) {
      this.filterModel = filterService.createFilter(this.projectDS, (p) => this.validateFilterValues(p), () => this.applyFilter());
    }

  ngOnDestroy(): void {
    this.filterModel.destroy();
  }

  getProjects() {
    this.settingProvider.getAdminSiteSettings().subscribe(settings => {
      if(settings.hideProjects) {
        this.router.navigate(['']);
        return;
      }
      this.apiClient.getProjects(!this.showOnlyActive).subscribe(
        data => {
          data.forEach(item => {
            item['start'] = item.startDate;
            item['end'] = item.endDate;
            item['isExpired'] = item.endDate < new Date();
          });
  
          this.projectDS.data = data.filter(x => x.id != 0);
        }
      );
    });
  }

  applyFilter() {
    this.getProjects();
  }

  validateFilterValues(params: Params) {
    this.showOnlyActive = params['showOnlyActive'] === 'true';
    if (params['showOnlyActive'] == undefined) {
      this.showOnlyActive = true;
    }
  }

  filterOnlyActive() {
    this.filterModel.applyFilter({ showOnlyActive: this.showOnlyActive });
  }

  ngOnInit() {
    this.projectDS.paginator = this.paginator;
    this.projectDS.sort = this.sort;

    this.canDelete = this.userService.getUser().role === 'SUPER_ADMIN';

    this.filterModel.loadFilterValuesAndApply();
  }

  applyQuckFilter(filterValue: string) {
    this.filterModel.applyQuickFilter(filterValue);
  }

  delete(project: IProject) {
    this.confirmService.translateAndOpen('PROJECT.DELETE_CONFIRM').subscribe(r => {
      if (r) {
        this.apiClient.deleteProject(project.id).subscribe(
          res => {
            this.projectDS.data = this.projectDS.data.filter((v, k) => {
              return v.id != project.id;
            });
          });
      }
    });
  }
}
