import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

import { AuthUserService } from './auth-user/auth-user.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authUserService: AuthUserService, @Inject('BASE_URL') private baseUrl: string) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let user = this.authUserService.getUser();
        if (user != null) {
            req = req.clone({
                setHeaders: {
                'Content-Type' : 'application/json; charset=utf-8',
                'Accept'       : 'application/json',
                'Authorization': `Bearer ${user.token}`,
                },
            });
        }
        return next.handle(req);
    }
}
