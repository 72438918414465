import { Component, OnInit } from '@angular/core';
import { AuthUserService } from '../../services/auth-user/auth-user.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AuthManager, SignInType, SignInOptions } from '../../services/auth-manager';
import { ApiClient } from 'src/app/services/api.client';
import { emojiValidator } from "../../shared";

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {
  registerForm: FormGroup;
  currentSignInState: any;

  constructor(private authManager: AuthManager, private authUserService: AuthUserService, private router: Router, private fb: FormBuilder, private apiClient: ApiClient) {
    this.initForm();
    this.currentSignInState = this.authManager.getCurrentSignInState();
  }

  register(type: string): void {
    const model = this.registerForm.value;
    this.authManager.signInRedirect(new SignInOptions({ type: <SignInType>type, returnState: model }));
  }

  initForm() {
    this.registerForm = this.fb.group({
      username: ['', [Validators.required, Validators.maxLength(2), Validators.minLength(2), emojiValidator()]],
      organization: ['', [Validators.required, emojiValidator()]],
      description: ['', [Validators.required, emojiValidator()]]
    });
  }

  processSignInCallback() {
    if(this.currentSignInState && this.currentSignInState.id_token) {
      var state = this.currentSignInState.options.returnState;

      this.registerForm.controls.username.setValue(state.username);
      this.registerForm.controls.organization.setValue(state.organization);
      this.registerForm.controls.description.setValue(state.description);

      let model = this.registerForm.value;
      this.authUserService.register(this.currentSignInState.id_token, this.currentSignInState.options.type, model.username, model.organization, model.description).then(r =>{
        this.router.navigate(['/']);
      });

      return true;
    }
    return false;
  }

  ngOnInit() {
    if (this.processSignInCallback()) {
      return;
    }

    if (this.authUserService.isSignedIn()) {
      this.router.navigate(['/']);
      return;
    }

    this.apiClient.isRegistrationAllowed().subscribe(r => {
      if (!r) {
        this.router.navigate(['/']);
      }
    })
  }
}
