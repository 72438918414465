import { Component, Inject, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Project, ApiClient, User, ProjectAssignment, IProjectAssignment } from '../../../services/api.client';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SelectAutocompleteComponent } from 'mat-select-autocomplete';
import { ConfirmationDialogService } from '../../../services/comfirmation-dialog.service';
import { DateHelper } from 'src/app/services/date-helper';
import { UserSelectDialog } from 'src/app/components/user-select-dialog/user-select-dialog.component';
import { AuthUserService } from 'src/app/services/auth-user/auth-user.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsProviderService } from 'src/app/services/settings-provider-service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { emojiValidator, getDisplayName } from "../../../shared";

export interface DialogData {
  users: any;
  selectedUserId: string;
}

@Component({
    selector: 'app-project-detail',
    templateUrl: './project-detail.component.html',
    styleUrls: ['./project-detail.component.css']
})
/** project-detail component*/
export class ProjectDetailComponent {
  projectForm: any;
  editMode: boolean;
  users: any[] = [];
  delUsers: number[];

  isSaving: boolean;
  project: Project = new Project();
  canEdit: boolean;
  projectAssignmentDS = new MatTableDataSource<IProjectAssignment>();
  displayedColumns: string[] = ['userName', 'actions'];

  /** standort-detail ctor */
  constructor(private formbulider: FormBuilder, private activatedRoute: ActivatedRoute,
      private apiClient: ApiClient, public dialog: MatDialog, private router: Router,
    private confirmService: ConfirmationDialogService, private userService: AuthUserService,
    private settingProvider: SettingsProviderService) { }

  ngOnInit() {
    this.projectForm = this.formbulider.group({
      name: ['', [Validators.required, emojiValidator()]],
      description: ['', [emojiValidator()]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]]
    });

    this.settingProvider.getAdminSiteSettings().subscribe(settings => {
      if(settings.hideProjects) {
        this.router.navigate(['']);
        return;
      }

      this.init();
    });
  }

  init() {
    this.isSaving = false;

    this.apiClient.getUsers().subscribe(u => {
      u.forEach(user => {
        this.users.push({ name: getDisplayName(user), id: user.id.toString() });
      });
      this.users = this.users.sort((a, b) => a.name.localeCompare(b.name));
    });

    this.projectForm = this.formbulider.group({
      name: ['', [Validators.required, emojiValidator()]],
      description: ['', [emojiValidator()]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]]
    });

    this.activatedRoute.params.subscribe(p => {
      var id = p['id'];
      if (id && id !== 'new') {
        this.apiClient.getProject(id).subscribe(project => {
          this.project = project;
          if (this.project.projectAssignment) {
            
            for (let projectAssignment of this.project.projectAssignment) {
              projectAssignment['userName'] = getDisplayName(projectAssignment.user);
            }
            this.project.projectAssignment = this.project.projectAssignment.sort((a, b) => a.user.name.localeCompare(b.user.name));

            this.projectAssignmentDS.data = this.project.projectAssignment;
          }
          this.projectForm.controls['name'].setValue(this.project.name);
          this.projectForm.controls['description'].setValue(this.project.description);
          this.projectForm.controls['startDate'].setValue(this.project.startDate);
          this.projectForm.controls['endDate'].setValue(this.project.endDate);
        });
      }
    });

    this.canEdit = this.userService.getUser().role === 'SUPER_ADMIN';
  }

  getDate(date: Date) {
    if (date) {
      return new Date(new Date(date).setHours(0, 0, 0, 0));
    }
  }

  applyFilter(filterValue: string) {
    this.projectAssignmentDS.filter = filterValue.trim().toLowerCase();
  }

  onFormSubmit() {
    this.project.name = this.projectForm.controls['name'].value;
    this.project.description = this.projectForm.controls['description'].value;
    this.project.startDate = new Date(this.projectForm.controls['startDate'].value);
    this.project.endDate = new Date(this.projectForm.controls['endDate'].value);

    this.isSaving = true;
    this.SaveProject();
  }

  SaveProject() {
    if (this.project.id !== undefined) {
      this.subscribeToSaveResponse(this.apiClient.putProject(this.project.id, this.project));
    } else {
      this.subscribeToSaveResponse(this.apiClient.postProject(this.project));
    }
  }

  private subscribeToSaveResponse(result) {
    result.subscribe((res) => this.onSaveSuccess(), (res: HttpErrorResponse) => this.onSaveError());
  }

  private onSaveSuccess() {
    this.isSaving = false;
    this.previousState();
  }

  private onSaveError() {
    this.isSaving = false;
  }

  previousState() {
    window.history.back();
  }

  openUserSelectDialog(): void {
    var assignedUsers = this.project.projectAssignment.map(m => {
      return m.userId;
    });
    const dialogRef = this.dialog.open(UserSelectDialog, {
      data: { selectedUserId: '', users: this.users.filter(v => {
        return assignedUsers.indexOf(parseInt(v.id)) == -1;
      }) }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.createAssignment(result);
    });
  }

  deleteAssignment(userId): void {
    this.confirmService.translateAndOpen('PROJECT.REMOVE_ASSIGNMENT_CONFIRM').subscribe(r => {
      if (r) {
        this.apiClient.deleteProjectAssignment(this.project.id + "_" + userId).subscribe(res => {
          this.ngOnInit();
        });
      }
    });
  }

  createAssignment(userIds: string[]): void {
    let completed = 0;
    userIds.forEach(userId => {
      var pa: ProjectAssignment = new ProjectAssignment();
      pa.projectId = this.project.id;
      pa.userId = Number(userId);

      this.apiClient.postProjectAssignment(pa).subscribe(res => {
        completed++;
        if (completed == userIds.length) {
          this.ngOnInit();
        }
      });
    });
  }
}
