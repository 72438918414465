import { Component, OnInit } from "@angular/core";
import { ApiClient, WorktimeSettings, SettingModel } from "src/app/services/api.client";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SettingsProviderService } from "src/app/services/settings-provider-service";

@Component({
    selector: 'settings-form',
    templateUrl: './settings-form.component.html',
    styleUrls: ['./settings-form.component.css']
  })
  export class SettingsFormComponent implements OnInit {
    form: FormGroup;
    settings: SettingModel;
  
    constructor(private apiClient: ApiClient, private formBuilder: FormBuilder, private settingsProvider: SettingsProviderService) { }
  
    ngOnInit() {
        this.settingsProvider.getSettings().subscribe(r => {
            this.settings = r;

            this.form = this.formBuilder.group({
                globalSettings: this.formBuilder.group({
                    allowRegisterNewOrganization: [this.settings.globalSettings.allowRegisterNewOrganization]
                }),
                adminSiteSettings: this.formBuilder.group({
                    hideProjects: [this.settings.adminSiteSettings.hideProjects]
                }),
                notificationSettings: this.formBuilder.group({
                    sendOverdueLoginNotification: [this.settings.notificationSettings.sendOverdueLoginNotification],
                    overdueLoginTime: [this.settings.notificationSettings.overdueLoginTime],
                    overdueLoginSummaryNotificationEmails: [this.settings.notificationSettings.overdueLoginSummaryNotificationEmails],
                    additionalSickLeaveNotificationEmails: [this.settings.notificationSettings.additionalSickLeaveNotificationEmails]
                }),
                worktimeSettings: this.formBuilder.group({
                    worktimeAutoPause: this.formBuilder.group({
                        duration: [this.settings.worktimeSettings.worktimeAutoPause.duration, [Validators.required, Validators.min(1)]],
                        pauseDuration: [this.settings.worktimeSettings.worktimeAutoPause.pauseDuration, [Validators.required, Validators.min(1)]]
                    }),
                    worktimePauseWarnings: this.formBuilder.array(this.settings.worktimeSettings.worktimePauseWarnings.map(s => this.formBuilder.group({
                        duration: [s.duration, [Validators.required, Validators.min(1)]],
                        pauseDuration: [s.pauseDuration, [Validators.required, Validators.min(1)]]
                    }))),
                    worktimeMaximumWarningMinutes: [this.settings.worktimeSettings.worktimeMaximumWarningMinutes, [Validators.required, Validators.min(1)]],
                    hoursAccountWarningRange: [this.settings.worktimeSettings.hoursAccountWarningRange, [Validators.required]]
                }),
                deleteDataSettings: this.formBuilder.group({
                  deleteCoordinatesMonths: [this.settings.deleteDataSettings.deleteCoordinatesMonths],
                  deleteWorktimeMonths: [this.settings.deleteDataSettings.deleteWorktimeMonths],
                  deleteSoftDeletedMonths: [this.settings.deleteDataSettings.deleteSoftDeletedMonths]
              })
            });
        });
    }

    validateEmailAddresses() {
        const re = /^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+)?(;\s?[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+)*$/;
        return re.test(this.form.get('notificationSettings.additionalSickLeaveNotificationEmails').value);
    }

    save() {
        if (this.form.valid) {
            this.apiClient.saveSettings(this.form.value).subscribe(() => {
              this.settingsProvider.resetSettings();
              this.ngOnInit();
            });
        }
    }

    //console
    lastPressed: string = "";
    command: string;
    commandResult: string;
    handleKeys(event) {
        if ('Control' == this.lastPressed && event.key == 'Enter') {
          this.executeCommand(this.command.trim());
        }
    
        this.lastPressed = event.key;
      }
    
      executeCommand(c: string) {
        if (c == '?') {
          this.setCoResult('Commands: / [sql], / $migrate, $calc_totals, ?');
          return;
        }
    
        if (c.startsWith('/') && c.length > c.indexOf('/') + 1) {
          this.apiClient.execute(c.substr(c.indexOf('/') + 1).trim()).subscribe(
            result => {
              this.setCoResult(result);
            });
        }
      }
    
      setCoResult(res: string) {
          this.commandResult = res;
      }
  }
