import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { ErrorMesageService } from "./error-message-service";
import { AuthUserService } from "./auth-user/auth-user.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ErrorHandleInterceptor implements HttpInterceptor {

  constructor(private errorMessageService: ErrorMesageService,
    private authUserService: AuthUserService, private translateService: TranslateService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((e, caught) => {
      this.blobToText(e.error).subscribe(error => {
        var errorObj;
        try {
          errorObj = JSON.parse(error);
        } catch {
          errorObj = error || e.error;
        }
        if (e.status === 401) {
          this.authUserService.signOut();
          return;
        }
        if (e.status !== 404) {
          this.errorMessageService.showError(this.toClientError(e.status, errorObj ? (errorObj.message || errorObj) : e.statusText, errorObj && errorObj.data));
        }
      })
      return throwError(e);
    }) as any);
  }

  blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
      if (!blob || (!blob.size && !blob.type)) {
        observer.next("");
        observer.complete();
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          observer.next((<any>event.target).result);
          observer.complete();
        };

        try {
          reader.readAsText(blob);
        } catch {
          observer.next("");
          observer.complete();
        }
      }
    });
  }

  toClientError(statusCode: any, error: any, details?: any) {
    let errors = [];
    if (statusCode === 400) {
      if (typeof (error) !== 'string' && error.errors) {
        for (var fieldName in error.errors) {
          if (error.errors.hasOwnProperty(fieldName) && error.errors[fieldName].length && error.errors[fieldName].length > 0) {
            errors.push(`${fieldName}: ${error.errors[fieldName].map(m => this.translateError(m, false)).join('\n')}`);
          }
        }
      }
      if (errors.length > 0) {
        return errors.join('\n');
      }
    }
    return this.translateError(error, true, details);
  }

  translateError(error: string, useDefaultError: boolean, details?: any) {
    var errorKey = 'ERROR.' + error;
    var translatedError = this.translatedNested(this.translateService.instant(errorKey, details || {}));
    return translatedError && translatedError != errorKey ? translatedError : (useDefaultError ? this.translateService.instant("ERROR.COMMON") : error);
  }

    translatedNested(error: string) {
        return error.replace(/\{\{([^}]*)\}\}/, (match, p1, p2, p3, offset, string) => {
            var translated = this.translateService.instant(p1);
            return translated === p1 ? match : translated;
        });
    }
}
