import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import { UserModel } from 'src/app/services/api.client';
import {NumberHelper} from '../services/number-helper';
import * as moment from 'moment';

export function emojiValidator(): ValidatorFn {
  const re: RegExp = new RegExp('[\u1000-\uFFFF]+', 'g');
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value == null) {
      return null;
    }
    const v = control.value.trim();
    const forbidden = re.test(v);
    return forbidden ? {hasEmoji: {value: control.value}} : null;
  };
}

export function emailValidator(): ValidatorFn {
  const re = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/;
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null) {
      return null;
    }
    const validEmail = re.test(control.value);
    return validEmail ? null : { invalidEmail: {value: control.value}};
  };
}

export function timeStringToMinutes(timeString): number | null {
  if (!timeString) {
    return null
  }

  const splittedStr = timeString.split(':')
  return NumberHelper.Parse(splittedStr, 0) * 60 + NumberHelper.Parse(splittedStr, 1)
}


export function dateRangeValidator(startFieldName: string = 'start', endFieldName: string = 'end', canBeEqual: boolean = false): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    const start = form.get(startFieldName).value
    const end = form.get(endFieldName).value
    if (start && end) {
      const isRangeValid = start < end || (canBeEqual && !(start > end))
      return isRangeValid ? null : {dateRange: true}
    }

    return null
  }
}

export function timeRangeValidator(startFieldName: string = 'start', endFieldName: string = 'end'): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    const start: number | null = timeStringToMinutes(form.get(startFieldName).value)
    const end: number | null = timeStringToMinutes(form.get(endFieldName).value)
    if (start && end) {
      const isRangeValid = (end - start > 0)
      return isRangeValid ? null : {timeRange: true}
    }

    return null
  }
}

export function validateWithCurrentTime(startFieldName: string = 'start', endFieldName: string = 'end'): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    const strDate = moment(form.get('date').value).format('YYYY-MM-DD ')
    if (!form.get(startFieldName).value || !form.get(endFieldName).value) {
      return null
    }
    const start = moment(strDate + form.get(startFieldName).value).toDate()
    const end = moment(strDate + form.get(endFieldName).value).toDate()
    const currentTime = new Date()
    if (start && end) {
      const isRangeValid = start <= currentTime && end <= currentTime
      return isRangeValid ? null : {timeRangeWithCurrentTime: true}
    }

    return null
  }
}

export function getDisplayName(user: UserModel) {
  if (user.firstName == null || user.lastName == null || user.firstName.trim() === '' || user.lastName.trim() === '') {
    return user.name;
  }
  return user.lastName + ', ' + user.firstName;
}
