import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, ValidationErrors} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {timeRangeValidator} from '../../../shared';
import {Observable} from 'rxjs';
import {ApiClient, Project} from '../../../services/api.client';
import { map } from 'rxjs/operators';
import { NumberHelper } from '../../../services/number-helper';

@Component({
  selector: 'app-change-project-dialog',
  templateUrl: './change-project-dialog.component.html',
  styleUrls: ['./change-project-dialog.component.css']
})
export class ChangeProjectDialogComponent implements OnInit {
  changeForm: FormGroup;
  projects$: Observable<Project[]>

  constructor(
    private readonly fb: FormBuilder,
    public readonly dialogRef: MatDialogRef<ChangeProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiClient: ApiClient
  ) { }

  private initForm() {
    this.changeForm = this.fb.group({
      time: [null, Validators.required],
      start: [this.getFormattedDateSrt(this.data.start)],
      end: [this.getFormattedDateSrt(this.data.stop)],
      project: [null, Validators.required]
    }, {
      validators: this.validateTimeBetweenStartEnd()
    })
  }

  private getFormattedDateSrt(date: Date) {
    const minutes = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
    return `${date.getHours()}:${minutes}`
  }

  ngOnInit() {
    this.initForm()
    this.projects$ = this.apiClient.getProjects(false).pipe(
      map(projs => {
        return projs.filter(p => {
          return p.id == 0 || p.projectAssignment != null && p.projectAssignment.find(pa => {
            return pa.userId == this.data.userId;
          });
        });
      })
    )
  }

  save() {
    const result = {...this.changeForm.value}
    this.dialogRef.close(result)
  }

  validateTimeBetweenStartEnd() {
    var getMinutes = this.getMinutes;
    return (formGroup: FormGroup): ValidationErrors => {
      let start = getMinutes(formGroup.controls.start.value);
      let end = getMinutes(formGroup.controls.end.value);
      let time = getMinutes(formGroup.controls.time.value);

      if (start && end) {
        let isValid = (time && start < time && time < end);
        if (!isValid) {
          return { time_should_be_between_worktime: true };
        }
      }
      return null;
    };
  }

  getMinutes(timeString: String): Number {
    if (!timeString) {
      return null;
    }

    var split = timeString.split(':');
    return NumberHelper.Parse(split, 0) * 60 + NumberHelper.Parse(split, 1);
  }
}
