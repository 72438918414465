import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MaterialModule } from './material.module'

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { UserModule } from './modules/user/user.module';
import { LoginFormComponent } from './forms/login-form/login-form.component';
import { AuthGuard } from './auth-guard';
import { AuthInterceptor } from './services/auth-interceptor';
import { RegisterFormComponent } from './forms/register-form/register-form.component';
import { ErrorHandleInterceptor } from './services/error-handle-interceptor';
import { VacationModule } from './modules/vacation/vacation.module';
import { ApiClient, BASE_URL } from './services/api.client';
import { StandortModule } from './modules/standort/standort.module';
import { WorktimeModule } from './modules/worktime/worktime.module';
import { environment } from '../environments/environment';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { LoadingInterceptor } from './services/loading-interceptor';
import { ProjectModule } from './modules/project/project.module';
import { SignInCallbackComponent } from './forms/sign-in-callback/sign-in-callback.component';
import { MY_FORMATS } from './services/date-helper';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NoCacheInterceptor } from './services/no-cache-interceptor';
import { TeamModule } from './modules/team/team-list/team.module';
import { SettingsFormComponent } from './forms/settings-form/settings-form.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { OrganizationListComponent } from './forms/organization-list/organization-list.component';
import { OrganizationSelector } from './services/auth-user/auth-user.service';
import { TranslateModule, TranslateLoader, TranslateService, TranslateParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatPaginatorIntl } from '@angular/material';
import { TranslatedMatPaginatorIntl } from './services/translated-mat-paginator-intl';
import { SettingsProviderService } from './services/settings-provider-service';
import { FilterService } from './services/filter-service';
import { VersionInfoComponent } from "./components/version-info/version-info.component";
import { AvailabilityModule } from "./modules/availability/availability.module"

export function getBaseUrl() {
  return environment.backendServerAddress;
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    LoginFormComponent,
    RegisterFormComponent,
    ConfirmationDialogComponent,
    MessageDialogComponent,
    SignInCallbackComponent,
    SettingsFormComponent,
    OrganizationListComponent,
    OrganizationSelector,
    VersionInfoComponent
  ],
  entryComponents: [ConfirmationDialogComponent, MessageDialogComponent, OrganizationSelector],
  exports: [ConfirmationDialogComponent, MessageDialogComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatNativeDateModule,
    UserModule,
    AvailabilityModule,
    VacationModule,
    StandortModule,
    ProjectModule,
    WorktimeModule,
    FormsModule,
    ReactiveFormsModule,
    TeamModule,
    NgxMaterialTimepickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AuthGuard,
    LoadingInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandleInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: LoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheInterceptor,
      multi: true,
    },
    {
      provide: BASE_URL,
      useFactory: getBaseUrl
    },
    ApiClient,
    SettingsProviderService,
    FilterService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MatPaginatorIntl, useClass: TranslatedMatPaginatorIntl, deps: [TranslateService, TranslateParser] },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

//hack to serialize dates with offset
Date.prototype.toISO = Date.prototype.toISOString;
Date.prototype.toJSON = function () {
  var timezoneOffsetInHours = -(this.getTimezoneOffset() / 60); //UTC minus local time

  //It's a bit unfortunate that we need to construct a new Date instance
  //(we don't want _this_ Date instance to be modified)
  var correctedDate = new Date(this.getFullYear(), this.getMonth(),
    this.getDate(), this.getHours(), this.getMinutes(), this.getSeconds(),
    this.getMilliseconds());
  correctedDate.setHours(this.getHours() + timezoneOffsetInHours);
  return correctedDate.toISO().replace('Z', '');
};

Date.prototype.toISOString = Date.prototype.toJSON;
