import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from 'src/app/auth-guard';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { WorktimeListComponent } from './worktime-list/worktime-list.component';
import { WorktimeDetailsComponent } from './worktime-details/worktime-details.component';
import { WorktimeRoutingModule } from './worktime-routing.module';
import { WorktimeApprovalListComponent, WorktimeApprovalAddMonthDialog } from './worktime-approval-list/worktime-approval-list.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { WorktimeDetailsDialog } from './worktime-approval-list/worktime-details-dialog/worktime-details-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import {WorktimeDetailsQuickEditDialog} from './worktime-approval-list/worktime-details-quick-details-dialog/worktime-details-quick-details-dialog';
import { WorkingHoursComponent } from './working-hours/working-hours.component';
import { ProjectHoursComponent } from './project-hours/project-hours.component';
import { AddEntryDialogComponent } from './add-entry-dialog/add-entry-dialog.component';
import { ChangeProjectDialogComponent } from './change-project-dialog/change-project-dialog.component';
import { CorrectionDetailsComponent } from './correction-details/correction-details.component';
import { CorrectionViewComponent } from './correction-view/correction-view.component';
import { CorrectionTableComponent } from './correction-table/correction-table.component';



@NgModule({
  declarations: [
    WorktimeListComponent,
    WorktimeApprovalListComponent,
    WorktimeDetailsComponent,
    WorktimeDetailsDialog,
    WorktimeApprovalAddMonthDialog,
    WorktimeDetailsQuickEditDialog,
    WorkingHoursComponent,
    ProjectHoursComponent,
    AddEntryDialogComponent,
    ChangeProjectDialogComponent,
    CorrectionDetailsComponent,
    CorrectionViewComponent,
    CorrectionTableComponent
  ],
  entryComponents: [
    WorktimeDetailsDialog,
    WorktimeApprovalAddMonthDialog,
    WorktimeDetailsQuickEditDialog,
    AddEntryDialogComponent,
    ChangeProjectDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WorktimeRoutingModule,
    MaterialModule,
    NgxMaterialTimepickerModule,
    TranslateModule
  ],
  providers: [AuthGuard]
})
export class WorktimeModule { }
