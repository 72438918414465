import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiClient, NameIdModel } from 'src/app/services/api.client';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmationDialogService } from 'src/app/services/comfirmation-dialog.service';
import { AuthUserService } from 'src/app/services/auth-user/auth-user.service';
import { Router } from '@angular/router';
import { SettingsProviderService } from 'src/app/services/settings-provider-service';

@Component({
  selector: 'organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.css']
})
export class OrganizationListComponent implements OnInit {
  organizations = new MatTableDataSource<NameIdModel>();
  displayedColumns: string[] = ['name', 'actions'];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private apiClient: ApiClient, private confirmService: ConfirmationDialogService, private authUserService: AuthUserService, private router: Router, private settingsProvider: SettingsProviderService) {
  }

  ngOnInit() {
    this.settingsProvider.getGlobalSettings().subscribe(r => {
        if (r.hasAccessToGlobalSettings) {
            this.loadOrganizations();
        } else {
            this.router.navigate(['/']);
        }
    });
    this.organizations.paginator = this.paginator;
    this.organizations.sort = this.sort;
  }

  canDelete(organization) {
      return organization.id !== this.authUserService.getUser().orgId;
  }

  loadOrganizations() {
    this.apiClient.getOrganizations().subscribe(t => {
      this.organizations.data = t;
    });
  }

  applyFilter(filterValue: string) {
    this.organizations.filter = filterValue.trim().toLowerCase();
  }

  delete(organization: NameIdModel) {
    this.confirmService.translateAndOpen('ORGANIZATION.DELETE_CONFIRM', organization).subscribe(r => {
      if (r) {
        this.apiClient.deleteOrganization(organization.id).subscribe(r => this.loadOrganizations());
      }
    });
  }
}