import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from '../../auth-guard';
import { MaterialModule } from '../../material.module';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserRegisterComponent } from './user-register/user-register.component';
import { UserRoutingModule } from './user-routing.module';
import { UserWorkingHours, UserWorkingHoursEditDialog } from './user-working-hours/user-working-hours.component';
import { UserHoursAccountAdjustment, UserHoursAccountAdjustmentEditDialog } from './user-hours-account-adjustment/user-hours-account-adjustment.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserAvailableVacationsEditDialog, UserAvailableVacations } from './user-available-vacations/user-available-vacations.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UserRoutingModule,
    MaterialModule,
    NgxMaterialTimepickerModule,
    TranslateModule
  ],
  providers: [AuthGuard],
  entryComponents: [UserWorkingHoursEditDialog, UserHoursAccountAdjustmentEditDialog, UserAvailableVacationsEditDialog],
  declarations: [
    UserListComponent,
    UserDetailComponent,
    UserRegisterComponent,
    UserWorkingHours,
    UserWorkingHoursEditDialog,
    UserHoursAccountAdjustment,
    UserHoursAccountAdjustmentEditDialog,
    UserAvailableVacations,
    UserAvailableVacationsEditDialog
    ]
})
export class UserModule { }
