import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectRoutingModule } from './project-routing.module';
import { MaterialModule } from '../../material.module';
import { AuthGuard } from '../../auth-guard';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { UserSelectDialog } from 'src/app/components/user-select-dialog/user-select-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProjectRoutingModule,
    MaterialModule,
    TranslateModule
  ],
  entryComponents: [UserSelectDialog],
  providers: [AuthGuard],
  declarations: [ProjectListComponent, ProjectDetailComponent, UserSelectDialog]})
export class ProjectModule {
}
