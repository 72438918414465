import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthManager, SignInType, SignInOptions } from 'src/app/services/auth-manager';
import { AuthUserService, AuthUser } from 'src/app/services/auth-user/auth-user.service';
import { ApiClient, UserRegisterModel, OAuth2Account } from '../../../services/api.client';

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.css']
})
/** user-register component*/
export class UserRegisterComponent {
  token: string;
  provider: SignInType;
  message: string;
  user: string;
  msgColor = "green";
  currentSignInState: any;

  /** user-register ctor */
  constructor(private authManager: AuthManager, private authUserService: AuthUserService, private route: ActivatedRoute, private apiClient: ApiClient, private router: Router) {
    this.message = "USER.REGISTRATION.CHECKING_TOKEN";
    this.currentSignInState = this.authManager.getCurrentSignInState();
  }

  register(type: SignInType) {
    this.authManager.signInRedirect(new SignInOptions({ type: <SignInType>type, returnState: {user: this.user, token: this.token } }));
  }

  processSignInCallback() {
    if(this.currentSignInState && this.currentSignInState.id_token) {
      this.user = this.currentSignInState.options.returnState.user;
      this.token = this.currentSignInState.options.returnState.token;

      var regUserModel = new UserRegisterModel({ token: this.token, id_token: this.currentSignInState.id_token, provider: OAuth2Account[SignInType[this.currentSignInState.options.type]]});
      this.apiClient.registerUser(regUserModel).subscribe(res => {
        this.message = "USER.REGISTRATION.SUCCESFULL";
        this.authUserService.signInUser(res as AuthUser);
        this.router.navigate(['/']);
      }, error => {
        this.msgColor = "red";
        this.message = "USER.REGISTRATION.ERROR";
      });

      return true;
    }
    return false;
  }

  ngOnInit() {
    if (this.processSignInCallback()) {
      return;
    }
    if(this.authUserService.isSignedIn()) {
      this.msgColor = "red";
      this.message = "USER.REGISTRATION.LOGGED_IN_ERROR";
      return;
    }

    this.route.params.subscribe(p => {
      this.token = p['token'];
      this.provider = p['provider'] as SignInType;
      if (this.token != null && this.provider != null) {
        this.apiClient.validateInviteToken(this.token, OAuth2Account[SignInType[this.provider]]).subscribe(u => {
          this.message = "USER.REGISTRATION.VALID_TOKEN";
          this.user = u.user;
          this.register(this.provider);
        },
          error => {
            this.msgColor = "red";
            this.message = "USER.REGISTRATION.INVALID_TOKEN_ERROR";
          });
      }
    })
  }
}
