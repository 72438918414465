import { NgModule, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Routes, RouterModule } from '@angular/router';
import { IProject, ApiClient, Project } from '../../services/api.client';
import { of } from 'rxjs';
import { AuthGuard } from '../../auth-guard';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';

const routes: Routes = [
  {
    path: 'project-list',
    component: ProjectListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'PROJECT.TITLE',
    }
  },
  {
    path: 'project-detail/:id/edit',
    component: ProjectDetailComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'PROJECT.TITLE',
    }
  },
  {
    path: 'project-detail/new',
    component: ProjectDetailComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'PROJECT.TITLE',
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]})
export class ProjectRoutingModule {
}
