import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ApiClient, TeamModel } from 'src/app/services/api.client';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmationDialogService } from 'src/app/services/comfirmation-dialog.service';
import { FilterService, FilterServiceModel } from 'src/app/services/filter-service';
import { AuthUser, AuthUserService } from 'src/app/services/auth-user/auth-user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.css']
})
export class TeamListComponent implements OnInit, OnDestroy {
  teams = new MatTableDataSource<TeamModel>();
  displayedColumns: string[] = ['name', 'teamLeaderName', 'canEditSelf', 'canEditAll', 'membersCount', 'modified', 'actions'];
  filterModel: FilterServiceModel;
  authUser: AuthUser;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private apiClient: ApiClient, private router: Router,
    filterService: FilterService, private confirmService: ConfirmationDialogService, private authUserService: AuthUserService) {
      this.filterModel = filterService.createFilter(this.teams, (p) => {}, () => {});
      this.teams.sortingDataAccessor = (data: any, sortHeaderId: string) => {
        const value: any = data[sortHeaderId === "modified" ? "modifiedDate" : sortHeaderId];
        return typeof value === "string" ? value.toLowerCase() : value;
      };
      this.teams.filterPredicate = (data, filter) => {
        return filterService.filterTableData([
          data.name,
          data.teamLeader.name,
          data.canEditAll ? "TEAM.EDIT_PERMISSIONS.ALL" : "TEAM.EDIT_PERMISSIONS.TEAM"
        ], filter);
      };
  }

  ngOnDestroy(): void {
    this.filterModel.destroy();
  }

  ngOnInit() {
    this.authUser = this.authUserService.getUser();

    this.teams.paginator = this.paginator;
    this.teams.sort = this.sort;

    this.filterModel.loadFilterValuesAndApply();
    this.loadTeams();
  }

  loadTeams() {
    this.apiClient.getTeams().subscribe(teams => {
      this.teams.data = teams;
    });
  }

  applyFilter(filterValue: string) {
    this.filterModel.applyQuickFilter(filterValue);
  }

  delete(team: TeamModel) {
    this.confirmService.translateAndOpen('TEAM.DELETE_CONFIRM', { name: team.name }).subscribe(r => {
      if (r) {
        this.apiClient.deleteTeam(team.id).subscribe(r => this.loadTeams());
      }
    });
  }

  checkCanEdit() {
    return this.authUser.role === "SUPER_ADMIN";
  }
}
