import { Component } from '@angular/core';
import { AuthUserService, AuthUser } from '../services/auth-user/auth-user.service';
import { ApiClient } from '../services/api.client';
import { timeout } from 'rxjs/operators';
import { LanguageConfigurator, Translation } from '../services/translation';
import { SettingsProviderService } from '../services/settings-provider-service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent {
  isExpanded = false;

  input: string = "";

  canvas:any = document.getElementById('bgMain');
  ctx: any = this.canvas.getContext('2d');
  w:number = this.canvas.width;
  h: number = this.canvas.height;
  cols:number = Math.floor(this.w / 6) + 1;
  ypos: any = Array(this.cols).fill(0);
  timerId: any;
  hasGlobalRights?: boolean;
  currentLanguage: string;
  hideProjects: boolean;

  constructor(
    private authUserService: AuthUserService,
    private languageConfigurator: LanguageConfigurator,
    private settingsProvider: SettingsProviderService) {

    this.ctx.fillStyle = '#000';
    this.ctx.fillRect(0, 0, this.w, this.h);
    this.currentLanguage = languageConfigurator.getCurrentLanguage();
  }

  setLanguage() {
    this.languageConfigurator.setLanquage(this.currentLanguage);
  }

  getLanguages() {
    return Translation.languages;
  }

  getUser() {
    var user = this.authUserService.getUser();
    if (user != null) {
      this.hasGlobalRights = this.hasGlobalRights;
      this.hideProjects = this.hideProjects;

      this.settingsProvider.getGlobalSettings().subscribe(s => {
        this.hasGlobalRights = s.hasAccessToGlobalSettings;
      });

      this.settingsProvider.getAdminSiteSettings().subscribe(s => {
        this.hideProjects = s.hideProjects;
      });
    } else {
      this.hasGlobalRights = false;
      this.hideProjects = false;
    }
    return user;
  }

  collapse() {
    this.isExpanded = false;
  }

  logOut() {
    this.authUserService.signOut();
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
