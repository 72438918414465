import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';

export interface ProjectDuration {
  name: string;
  duration: string;
}

@Component({
  selector: 'app-project-hours',
  templateUrl: './project-hours.component.html',
  styleUrls: ['./project-hours.component.scss'],
})
export class ProjectHoursComponent {
  @Input() durationByProject: ProjectDuration[]

  readonly displayedColumns = ['project', 'hours'];

  constructor() {
  }
}
