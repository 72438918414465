import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClient, IRole, User, UserWorktimeSettings, DeleteUserExternalId, OAuth2Account, Standort, LocationDeterminationType, CountryModel, StateModel, TimeZone } from '../../../services/api.client';
import { Observable } from 'rxjs';
import { ConfirmationDialogService } from 'src/app/services/comfirmation-dialog.service';
import { AuthUserService } from 'src/app/services/auth-user/auth-user.service';
import { SettingsProviderService } from 'src/app/services/settings-provider-service';
import {emojiValidator, emailValidator, getDisplayName} from "../../../shared";

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  readonly REST_USERNAME_DEF_VAL: string = "00000";

  user: User;
  headerUserName: string;
  roles: IRole[];
  isSaving: boolean;
  userForm: any;
  restUserName: string = this.REST_USERNAME_DEF_VAL;
  locations: Standort[];
  timeZones: TimeZone[];
  hideProjects: boolean;
  countries: CountryModel[] = [];
  states: StateModel[] = [];
  showCustomOverdueLoginTime: boolean = false;

  googleRegLink: string;
  microsoftRegLink: string;
  appleRegLink: string;
  locationDeterminationTypes: LocationDeterminationType[] = [LocationDeterminationType.WiFi, LocationDeterminationType.GPS, LocationDeterminationType.Both];

    constructor(private apiClient: ApiClient, private formbulider: FormBuilder,
    private activatedRoute: ActivatedRoute, private router: Router, private confirmService: ConfirmationDialogService,
    private authUserService: AuthUserService, private settingsProvider: SettingsProviderService) { }

  ngOnInit() {
    this.isSaving = false;

    this.settingsProvider.getAdminSiteSettings().subscribe(settings => this.hideProjects = settings.hideProjects);

    this.apiClient.getCountries().subscribe(countries => {
      this.countries = countries;
      if (this.user) {
        let country = this.countries.find(country => country.id === this.user.countryId);
        this.states = country ? country.states : null;
      }
    });
    this.apiClient.getRole().subscribe(
      data => this.roles = data
    );

    this.apiClient.getStandortAll().subscribe(
      x => this.locations = x.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    );

    this.apiClient.getTimeZones().subscribe(
      x => this.timeZones = x
    );

    this.userForm = this.formbulider.group({
      name: ['', [Validators.required, Validators.maxLength(2), Validators.minLength(2), emojiValidator()]],
      role: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, emailValidator()]],
      countryId: ['', [this.requiredForRfidUser(false)]],
      stateId: ['', [this.requiredForSelectedCountryAndNotRfidUser()]],
      rfidLocationId: ['', [this.requiredForRfidUser(true)]],
      timeZoneId: [''],
      locationDeterminationType: ['', [this.requiredForRfidUser(false)]],
      googleId: [''],
      microsoftId: [''],
      appleId: [''],
      loggedInDevice: [''],
      addAutoPause: [''],
      preFillNewMonth: [''],
      preFillHomeOffice: [''],
      notifyAbsenceStatusChanged: [''],
      notifyOverdueLogin: [''],
      useCustomOverdueLoginTime: [''],
      customOverdueLoginTime: ['', [this.requiredUseCustomOverdueLoginTime()]]
    });

    this.userForm.controls.role.valueChanges.subscribe(() => {
      this.userForm.controls.rfidLocationId.updateValueAndValidity();
      this.userForm.controls.countryId.updateValueAndValidity();
      this.userForm.controls.stateId.updateValueAndValidity();
      this.userForm.controls.locationDeterminationType.updateValueAndValidity();
    });

    this.activatedRoute.data.subscribe(({ user }) => {
      this.user = user;
      if (this.user.projectAssignment) {
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        this.user.projectAssignment = this.user.projectAssignment
          .filter(x => x.project.endDate === null || x.project.endDate >= today)
          .sort((a, b) => a.project.name.localeCompare(b.project.name));
      }

      if (user.id !== undefined) {
        this.restUserName = this.user.name.substr(2, this.user.name.length - 2);
        this.userForm.controls['name'].setValue(this.user.name.substr(0, 2));

        this.headerUserName = getDisplayName(this.user);

        this.userForm.controls['role'].setValue(this.user.role.id);
        this.userForm.controls['addAutoPause'].setValue(this.user.worktimeSettings && this.user.worktimeSettings.autoPauseEnabled);
        this.userForm.controls['rfidLocationId'].setValue(this.user.rfiD_LocationId);
        this.userForm.controls['timeZoneId'].setValue(this.user.timeZoneId);
        this.userForm.controls['locationDeterminationType'].setValue(this.user.worktimeSettings && this.user.worktimeSettings.locationDeterminationType || LocationDeterminationType.WiFi);
        this.userForm.controls['countryId'].setValue(this.user.countryId);
        this.userForm.controls['stateId'].setValue(this.user.stateId);
        this.userForm.controls['preFillNewMonth'].setValue(this.user.worktimeSettings && this.user.worktimeSettings.preFillNewMonth);
        this.userForm.controls['preFillHomeOffice'].setValue(this.user.worktimeSettings && this.user.worktimeSettings.preFillHomeOffice);
        this.userForm.controls['notifyAbsenceStatusChanged'].setValue(this.user.worktimeSettings && this.user.worktimeSettings.notifyAbsenceStatusChanged);
        this.userForm.controls['notifyOverdueLogin'].setValue(this.user.worktimeSettings && this.user.worktimeSettings.notifyOverdueLogin);
        this.userForm.controls['useCustomOverdueLoginTime'].setValue(this.user.worktimeSettings && this.user.worktimeSettings.useCustomOverdueLoginTime);
        this.userForm.controls['customOverdueLoginTime'].setValue(this.user.worktimeSettings.customOverdueLoginTime ? this.user.worktimeSettings.customOverdueLoginTime.substr(0, 5) : null);

        this.showCustomOverdueLoginTime = this.userForm.controls['useCustomOverdueLoginTime'].value;

        this.userForm.controls['loggedInDevice'].setValue(this.user.userSession && this.user.userSession.length > 0);
        if (!this.user.userSession || this.user.userSession.length == 0)
          this.userForm.controls['loggedInDevice'].disable();

        this.user.oAuth2UserAccount.forEach(a => {
          switch (a.accountName) {
            case OAuth2Account.Google: {
              this.userForm.controls['googleId'].setValue(a.userExtID);
              this.setRegLink(a.inviteToken, 'Google');
              break;
            }
            case OAuth2Account.Microsoft: {
              this.userForm.controls['microsoftId'].setValue(a.userExtID);
              this.setRegLink(a.inviteToken, 'Microsoft');
              break;
            }
            case OAuth2Account.Apple: {
              this.userForm.controls['appleId'].setValue(a.userExtID);
              this.setRegLink(a.inviteToken, 'Apple');
              break;
            }
            default: { }
          }
        });
      } else {
        this.userForm.controls['loggedInDevice'].disable();
      }
    });
  }

  requiredForRfidUser(required) {
    var self = this;
    return (c: AbstractControl) => {
      if(self.isRfidRoleSelected() === required) {
        return Validators.required(c);
      }
      return null;
    }
  }

  requiredForSelectedCountryAndNotRfidUser() {
    var self = this;
    return (c: AbstractControl) => {
      if ((!self.isRfidRoleSelected()) && self.hasStates()) {
        return Validators.required(c);
      }
      return null;
    }
  }

  requiredUseCustomOverdueLoginTime() {
    var self = this;
    return (c: AbstractControl) => {
      if ((!self.isRfidRoleSelected()) && self.showCustomOverdueLoginTime) {
        return Validators.required(c);
      }
      return null;
    }
  }

  isRfidRoleSelected() {
    var rfidRole = this.roles && this.roles.find(x => x.name === 'RFID_APP_USER');
    return rfidRole && this.userForm && this.userForm.controls && this.userForm.controls.role && this.userForm.controls.role.value == rfidRole.id;
  }

  onFormSubmit() {
    this.user.name = "" + this.userForm.controls['name'].value + this.restUserName;
    this.user.roleId = this.userForm.controls['role'].value;
    this.user.org = null;
    this.user.oAuth2UserAccount = null;
    this.user.role = null;
    if(this.isRfidRoleSelected()) {
      this.user.rfiD_LocationId = this.userForm.controls.rfidLocationId.value;
      this.user.timeZoneId = this.userForm.controls.timeZoneId.value;
      this.user.countryId = null;
      this.user.stateId = null;
      this.user.worktimeSettings.preFillNewMonth = false;
      this.user.worktimeSettings.preFillHomeOffice = true;
      this.user.worktimeSettings.notifyAbsenceStatusChanged = false;
      this.user.worktimeSettings.notifyOverdueLogin = false;
      this.user.worktimeSettings.useCustomOverdueLoginTime = false;
      this.user.worktimeSettings.customOverdueLoginTime = null;
    }
    else {
      this.user.rfiD_LocationId = null;
      this.user.countryId = this.userForm.controls.countryId.value;
      this.user.stateId = this.userForm.controls.stateId.value;
      this.user.worktimeSettings.autoPauseEnabled = !!this.userForm.controls['addAutoPause'].value;
      this.user.worktimeSettings.locationDeterminationType = this.userForm.controls['locationDeterminationType'].value;
      this.user.worktimeSettings.preFillNewMonth = !!this.userForm.controls['preFillNewMonth'].value;
      this.user.worktimeSettings.preFillHomeOffice = !!this.userForm.controls['preFillHomeOffice'].value;
      this.user.worktimeSettings.notifyAbsenceStatusChanged = !!this.userForm.controls['notifyAbsenceStatusChanged'].value;
      this.user.worktimeSettings.notifyOverdueLogin = !!this.userForm.controls['notifyOverdueLogin'].value;
      this.user.worktimeSettings.useCustomOverdueLoginTime = !!this.userForm.controls['useCustomOverdueLoginTime'].value;
      this.user.worktimeSettings.customOverdueLoginTime = this.userForm.controls['customOverdueLoginTime'].value;
    }
    this.isSaving = true;
    this.SaveUser();
  }

  private subscribeToSaveResponse(result) {
    result.subscribe((res: User) => this.onSaveSuccess(res), (res: HttpErrorResponse) => this.onSaveError());
  }

  private onSaveSuccess(user: User) {
    this.isSaving = false;
      if (user) {
          this.user = user;

          this.generateInviteLinks().subscribe(null, null,
              () => {
                  this.router.navigate(['/user-detail/' + user.id + '/edit']);
              }
          );
      }
      else {
          this.previousState();
      }
  }

  private onSaveError() {
    this.isSaving = false;
  }

  SaveUser() {
    if (this.user.id !== undefined) {
      this.subscribeToSaveResponse(this.apiClient.putUser(this.user.id, this.user));
    } else {
      this.subscribeToSaveResponse(this.apiClient.postUser(this.user));
    }
  }

  previousState() {
    window.history.back();
  }

  generateRestName() {
    this.apiClient.generateName(this.userForm.controls['name'].value).subscribe(res => {
      this.restUserName = res.substr(2, res.length);
    });
  }

  generateInviteLinks(): Observable<void> {
    return new Observable<void>(observer => {
      this.apiClient.generateInviteToken(this.user.id, OAuth2Account.Google).subscribe(res => {
        this.setRegLink(res, 'Google');
        this.apiClient.generateInviteToken(this.user.id, OAuth2Account.Microsoft).subscribe(res => {
          this.setRegLink(res, 'Microsoft');
          this.apiClient.generateInviteToken(this.user.id, OAuth2Account.Apple).subscribe(res => {
            this.setRegLink(res, 'Apple');
            observer.complete();
          });
        });
      });
    });
  }

  onGenerateInviteLinks() {
    this.generateInviteLinks().subscribe(null, null,() => {});
  }

  private setRegLink(token, provider) {
    if (token != null && token !== "")
      switch(provider) {
        case 'Google':
          this.googleRegLink = `https://timeflow.online/user-register/${token}/${provider}`;
          break;
        case 'Microsoft':
          this.microsoftRegLink = `https://timeflow.online/user-register/${token}/${provider}`;
          break;
        case 'Apple':
          this.appleRegLink = `https://timeflow.online/user-register/${token}/${provider}`;
          break;
      }
  }

  private sendInvite(inviteUrl) {
    var toEmail = this.user.email;
    if (toEmail != null && toEmail !== "" && inviteUrl != null && inviteUrl !== "") {
      this.apiClient.getUsers().subscribe((users) => {
        var currentUser = users.find(({ name }) => name === this.authUserService.getUser().username);
        if (currentUser !== undefined) {
          var toName = this.user.firstName + " " + this.user.lastName;
          var ccEmail = currentUser.email == null ? "" : currentUser.email;
          var ccName = currentUser.firstName + " " + currentUser.lastName;
          this.apiClient.sendInvite(inviteUrl, toEmail, toName, ccEmail, ccName).subscribe(() => {
            alert("Email Sent Successfully");
          });
        }
        else {
          var toName = this.user.firstName + " " + this.user.lastName;
          this.apiClient.sendInvite(inviteUrl, toEmail, toName, null, null).subscribe(() => {
            alert("Email Sent Successfully");
          });
        }
      });
    }
  }

  validateEmailAddress(emailAddress) {
    if (!emailAddress) {
      return false;
    }
    const re = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/;
    return re.test(emailAddress);
  }

  //src: https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
  copyToClipboard(value: string) {
    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = value;                               // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =
      document.getSelection().rangeCount > 0        // Check if there is any content selected previously
        ? document.getSelection().getRangeAt(0)     // Store selection if found
        : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
      document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
      document.getSelection().addRange(selected);   // Restore the original selection
    }
  }

  onUserNamePreffixChange(value: string) {
    if (value.length == 2 && (this.restUserName == null || this.restUserName == "" || this.restUserName == this.REST_USERNAME_DEF_VAL)) {
      this.generateRestName();
    }
  }

  onSelectedCountryChanged(value) {
    this.userForm.controls['stateId'].setValue(null);
    this.states = this.countries.find(c => c.id === value).states;
    this.userForm.controls.stateId.updateValueAndValidity();
  }

  logout() {
    this.confirmService.translateAndOpen('USER.LOG_OUT_CONFIRM').subscribe(r => {
      if (r) {
        this.apiClient.deleteSession(this.user.id).subscribe(d => {
          this.user.userSession = [];
          this.userForm.controls['loggedInDevice'].disable();
        });
      } else {
        this.userForm.controls['loggedInDevice'].setValue(true);
      }
    });
  }

  deleteAccount(control, provider) {
    this.confirmService.translateAndOpen("USER.DELETE_OAUTH_ACCOUNT_CONFIRM", { provider: provider }).subscribe(r => {
      if(r) {
        this.apiClient.deleteUserExternalId(new DeleteUserExternalId({ userId: this.user.id, provider: provider })).subscribe(d => {
          this.apiClient.generateInviteToken(this.user.id, provider).subscribe(t => {
            this.setRegLink(t, provider);
            this.userForm.controls[control].setValue('');
          });
        });
      }
    });
  }

  canEdit() {
    return this.authUserService.getUser().role == 'SUPER_ADMIN';
  }

  canEditByTeamLeader() {
    return this.authUserService.getUser().role == 'WORKTIME_ADMIN' && this.authUserService.getUser().username != this.user.name;
  }

  canEditByAdminOrTeamLeader() {
    return this.canEdit() || this.canEditByTeamLeader();
  }

  hasStates() {
    return this.states && this.states.length > 0;
  }

  updateShowCustomOverdueLoginTime() {
    this.showCustomOverdueLoginTime = this.userForm.controls['useCustomOverdueLoginTime'].value;
    if (!this.showCustomOverdueLoginTime) {
      this.userForm.controls['customOverdueLoginTime'].setValue(null);
    }
  }
}
