import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class AuthManager {
  private signInParamsKey: string = "sign_in_params";

  constructor(private router: Router) {
  }

  public getCurrentSignInState() {
    var navigation = this.router.getCurrentNavigation();
    if(navigation && navigation.extras) {
      return navigation.extras.state;
    }
    return null;
  }

  public signInRedirect(options: SignInOptions) {
    this.redirect(options);
  }

  redirect(options: SignInOptions) {
    var baseUrls = {
      'Google': 'https://accounts.google.com/o/oauth2/v2/auth',
      'Microsoft': 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
      'Apple': 'https://appleid.apple.com/auth/authorize'
    };

    var clientIds = {
      'Google': environment.oauth2.googleClientId,
      'Microsoft': environment.oauth2.microsoftClientId,
      'Apple': environment.oauth2.appleClientId
    }

    options.returnUrl = window.location.pathname;
    var callbackParams = new SignInParams({
      state: this.generateRandom(),
      nonce: this.generateRandom(),
      options: options,
      date: new Date()
    });

    var params = {
      client_id: clientIds[options.type],
      state: callbackParams.state,
      nonce: callbackParams.nonce,
      redirect_uri: window.location.origin + '/signin_callback',
      scope: 'openid',
      response_type: 'id_token'
    };

    if (options.type == SignInType.Apple) {
      params['response_type'] = 'code id_token';
      params['nonce'] = undefined;
      params['response_mode'] = 'fragment';
    }
    if (options.type == SignInType.Microsoft) {
      params['prompt'] = 'select_account';
    }

    localStorage.setItem(this.signInParamsKey, JSON.stringify(callbackParams));

    window.location.href = baseUrls[options.type] + '?' + this.toQueryString(params);
  }

  getSignInParams() {
    var paramsJSON = localStorage.getItem(this.signInParamsKey);
    if(!paramsJSON) {
      console.error('could not find sign in params');
      return null;
    }
    try {
      var params = JSON.parse(paramsJSON);

      var expire = new Date(new Date(params.date).getTime() + 10 * 60000);
      localStorage.removeItem(this.signInParamsKey);

      if (expire >= new Date()) {
        return new SignInParams(params);
      }
      console.error('sign in params has been expired, create date: ' + params.date + ', expiration: 10 minutes');
      return null;
    } catch {
      console.error('could not parse sign in params: ' + paramsJSON);
      return null;
    }
  }

  generateRandom() {
    return Math.random().toString(36).substring(2, 15);
  }

  toQueryString(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p) && obj[p]) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }
}

export class SignInParams {
  public state: string;
  public nonce: string
  public date: Date;
  public options: SignInOptions;

  public constructor(init?:Partial<SignInParams>) {
    Object.assign(this, init);
  }
}

export class SignInOptions {
  public returnState: any;
  public type: SignInType;
  public returnUrl: string;

  public constructor(init?:Partial<SignInOptions>) {
    Object.assign(this, init);
  }
}

export enum SignInType {
  Google = 'Google',
  Microsoft = 'Microsoft',
  Apple = 'Apple'
}