import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {WorktimeWarningModel} from '../../../services/api.client';

@Component({
  selector: 'app-working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkingHoursComponent {
  @Input() width = '500px';
  @Input() height = '140px';
  @Input() totalDuration: string;
  @Input() pauseDuration: string;
  @Input() isHoursError;
  @Input() isPauseError;
  @Input() isError;
  @Input() error: string;
  @Input() warnings: WorktimeWarningModel[]

  constructor() { }
}
