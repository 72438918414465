import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginFormComponent } from './forms/login-form/login-form.component';
import { AuthGuard } from './auth-guard';
import { RegisterFormComponent } from './forms/register-form/register-form.component';
import { SignInCallbackComponent } from './forms/sign-in-callback/sign-in-callback.component';
import { SettingsFormComponent } from './forms/settings-form/settings-form.component';
import { OrganizationListComponent } from './forms/organization-list/organization-list.component';
import { VersionInfoComponent } from "./components/version-info/version-info.component";

export const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  {
    path: 'login',
    component: LoginFormComponent,
    data: {
      htmlClass: "bg-white",
    },
  },
  {
    path: 'register',
    data: {
      title: 'ORGANIZATION.REGISTER_TITLE',
      htmlClass: "bg-white",
      widePage: true,
      hideNavbar: true,
    },
    component: RegisterFormComponent
  },
  {
    path: '--version',
    component: VersionInfoComponent
  },
  { path: 'signin_callback', component: SignInCallbackComponent },
  { path: 'settings', component: SettingsFormComponent, canActivate: [AuthGuard], data: { title: 'SETTINGS.TITLE' } },
  { path: 'organizations', data: { title: 'ORGANIZATION.TITLE' } , component: OrganizationListComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {
}
