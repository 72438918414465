declare var DockerEnv;

export const environment = {
  production: DockerEnv.ASPNETCORE_ENVIRONMENT !== "Development",

  oauth2: {
    microsoftClientId: DockerEnv.TF_OPENID_MICROSOFT,
    googleClientId: DockerEnv.TF_OPENID_GOOGLE,
    appleClientId: DockerEnv.TF_OPENID_APPLE
  },

  backendServerAddress: DockerEnv.TF_BACKEND_URL
};
