import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from "@angular/router";
import { ApiClient, IUser, User, IUserModel, UserModel } from '../../../services/api.client';
import { ConfirmationDialogService } from '../../../services/comfirmation-dialog.service';
import { AuthUserService } from 'src/app/services/auth-user/auth-user.service';
import { SettingsProviderService } from 'src/app/services/settings-provider-service';
import { NumberHelper } from 'src/app/services/number-helper';
import { FilterService, FilterServiceModel } from 'src/app/services/filter-service';
import { saveAs } from 'file-saver';
import * as moment from 'moment';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, OnDestroy {
  hoursAccountWarningRange: number;
  userDS = new MatTableDataSource<IUserModel>();
  displayedColumns: string[] = ['name', 'firstName', 'lastName', 'email', 'roleName', 'projectCount', 'hoursAccount', 'vacations', 'holidays', 'sickDays', 'specialLeaveDays', 'oauth2accounts', 'actions'];
  filterModel: FilterServiceModel;

  availableYears: any[];
  year: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
 
  constructor(private router: Router, private apiClient: ApiClient, private confirmService: ConfirmationDialogService,
    private authUserService: AuthUserService, private settingsProvider: SettingsProviderService, filterService: FilterService) { 
    this.filterModel = filterService.createFilter(this.userDS, (p) => { }, () => { });

    this.year = new Date().getFullYear();
    this.availableYears = [this.year, this.year - 1, this.year - 2];
  }

  ngOnDestroy(): void {
    this.filterModel.destroy();
  }

  getUsers() {
    this.apiClient.getUsers().subscribe(users => {
      this.userDS.data = users;
    });
  }

  updateYear() {
    this.apiClient.getUsersForYear(this.year).subscribe(users => {
      this.userDS.data = users;
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ngOnInit() {
    this.settingsProvider.getWorktimeSettings().subscribe(x => this.hoursAccountWarningRange = x.hoursAccountWarningRange * 60);

    this.getUsers();
    this.userDS.paginator = this.paginator;
    this.userDS.sort = this.sort;
    this.userDS.sortData = (data, sort) => {
      return data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name': return this.compare(a.name, b.name, isAsc);
          case 'firstName': return this.compare(a.firstName, b.firstName, isAsc);
          case 'lastName': return this.compare(a.lastName, b.lastName, isAsc);
          case 'roleName': return this.compare(a.roleName, b.roleName, isAsc);
          case 'projectCount': return this.compare(a.projectCount, b.projectCount, isAsc);
          case 'hoursAccount': return this.compare(this.parseHoursAccount(a.hoursAccount), this.parseHoursAccount(b.hoursAccount), isAsc);
          case 'vacations': return this.compare(a.vacations.available, b.vacations.available, isAsc);
          case 'holidays': return this.compare(a.holidays, b.holidays, isAsc);
          case 'sickDays': return this.compare(a.sickDays, b.sickDays, isAsc);
          case 'oauth2accounts': return this.compare(a.accounts, b.accounts, isAsc);
          default: return 0;
        }
      });
    }
    this.filterModel.loadFilterValuesAndApply();
  }

  parseHoursAccount(hoursAccount:string) : number {
    var minus = hoursAccount.startsWith('-');
    var hours = NumberHelper.Parse(hoursAccount.replace('-', '').split(':'), 0);
    var minutes = NumberHelper.Parse(hoursAccount.replace('-', '').split(':'), 1);

    return (minus ? -1 : 1) * (hours * 60 + minutes);
  }

  applyFilter(filterValue: string) {
    this.filterModel.applyQuickFilter(filterValue);
  }

  delete(user: IUserModel) {
    this.confirmService.translateAndOpen('USER.DELETE_CONFIRM', { user_name: user.name }).subscribe(r => {
      if (r) {
        this.apiClient.deleteUser(user.id).subscribe(
          res => {
            this.userDS.data = this.userDS.data.filter((v, k) => {
              return v.id != user.id;
            });
          });
      }
    });
  }

  canRemove() {
    return this.authUserService.getUser().role == 'SUPER_ADMIN';
  }

  navigateToDetails(id:number) {
    this.router.navigate(['/user-detail', id, 'edit'])
  }

  isHoursAccountOutRange(user: UserModel) {
    var components = user.hoursAccount.replace('-', '').split(':');
    var hoursAccount = NumberHelper.Parse(components, 0) * 60 + NumberHelper.Parse(components, 1);

    return hoursAccount > this.hoursAccountWarningRange;
  }

  exportCurrentUserList() {
    const today = moment();
    const fileName = 'UsersExport_' + this.year + '_' + today.format('YYYY-MM-DD_HH-mm') + '.csv';
    let csvData = 'Username;Vorname;Nachname;Email;Rolle;Projektanzahl;Gleitzeitkonto;Jahresurlaub;Urlaub entnommen;Urlaub geplant;Feiertage;Kranktage;Accounts\n';

    this.userDS.data.forEach((user) => {
      let firstName = user['firstName'];
      if (firstName == null)
        firstName = '';

      let lastName = user['lastName'];
      if (lastName == null)
        lastName = '';

      let email = user['email'];
      if (email == null)
        email = '';

      csvData += user.name + ';' + firstName + ";" + lastName + ';' + email + ';' + user.roleName + ';' + user.projectCount + ';\'' + user.hoursAccount + ';'
        + user.vacations.actualAvailable + ';' + user.vacations.taken + ';' + user.vacations.planned + ';' + user.holidays + ';' + user.sickDays + ';' + user.accounts + '\n';
    });

    const csvBlob = new Blob(["\ufeff", csvData]);
    saveAs(csvBlob, fileName);
  }
}
