import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ApiClient, IPresenceModel, PresenceType } from '../../services/api.client';
import { FilterService, FilterServiceModel } from 'src/app/services/filter-service';

@Component({
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.css']
})
export class AvailabilityComponent implements OnInit, OnDestroy {
  hoursAccountWarningRange: number;
  presenceDS = new MatTableDataSource<IPresenceModel>();
  displayedColumns: string[] = ['presence', 'absenceEndDate', 'firstName', 'lastName', 'teamName'];
  filterModel: FilterServiceModel;
  onlyOwnTeams: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
 
  constructor(private apiClient: ApiClient, filterService: FilterService) { 
    this.filterModel = filterService.createFilter(this.presenceDS, (p) => { }, () => this.applyFilter());
  }

  ngOnDestroy(): void {
    this.filterModel.destroy();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareDate(a: Date, b: Date, isAsc: boolean) {
    if (!a)
      return isAsc ? -1 : 1;
    if (!b)
      return isAsc ? 1 : -1;
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ngOnInit() {
    this.onlyOwnTeams = true;
    this.presenceDS.paginator = this.paginator;
    this.presenceDS.sort = this.sort;
    this.presenceDS.sortData = (data, sort) => {
      return data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'presence': return this.compare(this.presenceToSortableNumber(a), this.presenceToSortableNumber(b), isAsc);
          case 'absenceEndDate': return this.compareDate(a.absenceEndDate, b.absenceEndDate, isAsc);
          case 'firstName': return this.compare(a.firstName, b.firstName, isAsc);
          case 'lastName': return this.compare(a.lastName, b.lastName, isAsc);
          case 'teamName': return this.compare(a.teamName, b.teamName, isAsc);
          default: return 0;
        }
      });
    }
    this.filterModel.loadFilterValuesAndApply();
  }

  applyQuickFilter(filterValue: string) {
    this.filterModel.applyQuickFilter(filterValue);
  }

  applyFilter() {
    this.apiClient.getPresences(this.onlyOwnTeams).subscribe(presences => {
      this.presenceDS.data = presences;
    });
  }

  /****************************************************************************
  * 00 | Present                       | PRESENT                 | Green      *
  * 01 | Customer Project              | CUSTOMER_PROJECT        | Green      *
  * 02 | Customer Project & HomeOffice | CUSTOMER_PROJECT        | LightGreen *
  * 03 | HomeOffice                    | MOBILE_WORK             | LightGreen *
  * 04 | BusinessTrip                  | BUSINESS_TRIP           | LightGreen *
  * 05 | AFK                           | AFK                     | Black      *
  * 06 | Flextime                      | FLEXTIME                | Gray       *
  * 07 | RestDay                       | REST_DAY                | Gray       *
  * 08 | Holiday                       | HOLIDAY                 | Gray       *
  * 09 | Vacation                      | VACATION                | Gray       *
  * 10 | Child Sick Leave              | APPROVED_ABSENCE        | Gray       *
  * 11 | Special Leave                 | APPROVED_ABSENCE        | Gray       *
  * 12 | Unverified Sick Leave         | APPROVED_ABSENCE        | DarkGray   *
  * 13 | Verified Sick Leave           | APPROVED_ABSENCE        | DimGray    *
  * 14 | BreakOrDone                   | BREAK_OR_DONE           | Orange     *
  * 15 | Call                          | CALL                    | Orange     *
  * 16 | Intense Work                  | INTENSE_WORK            | Orange     *
  * 17 | Free For Work                 | FREE_FOR_WORK           | Orange     *
  * 18 | Internal Project              | INTERNAL_PROJECT        | Orange     *
  * 19 | WaitingAbsence                | WAITING_ABSENCE_REQUEST | Red        *
  * 20 | Absent                        | ABSENT                  | Red        *
  * 21 | DEFAULT                       | UNKNOWN                 | Gray       *
  ****************************************************************************/

  presenceToTranslatableText(value: IPresenceModel) {
    switch (value.userState) {
      case 1:
        return "AVAILABILITY.USER_STATE.CALL";
      case 2:
        return "AVAILABILITY.USER_STATE.AFK";
      case 3:
        return "AVAILABILITY.USER_STATE.INTENSE_WORK";
      case 4:
        return "AVAILABILITY.USER_STATE.FREE_FOR_WORK";
      case 5:
        return "AVAILABILITY.USER_STATE.INTERNAL_PROJECT";
      case 6:
        return "AVAILABILITY.USER_STATE.CUSTOMER_PROJECT";
      default:
        break;
    }

    switch (value.presence) {
      case PresenceType.Present:
        return "AVAILABILITY.PRESENCE_TYPE.PRESENT";
      case PresenceType.Absent:
        return "AVAILABILITY.PRESENCE_TYPE.ABSENT";
      case PresenceType.BreakOrDone:
        return "AVAILABILITY.PRESENCE_TYPE.BREAK_OR_DONE";
      case PresenceType.RestDay:
        return "AVAILABILITY.PRESENCE_TYPE.REST_DAY";
      case PresenceType.Vacation:
        return "AVAILABILITY.PRESENCE_TYPE.VACATION";
      case PresenceType.Holiday:
        return "AVAILABILITY.PRESENCE_TYPE.HOLIDAY";
      case PresenceType.Flextime:
        return "AVAILABILITY.PRESENCE_TYPE.FLEXTIME";
      case PresenceType.BusinessTrip:
        return "AVAILABILITY.PRESENCE_TYPE.BUSINESS_TRIP";
      case PresenceType.HomeOffice:
        return "AVAILABILITY.PRESENCE_TYPE.MOBILE_WORK";
      case PresenceType.WaitingAbsence:
        return "AVAILABILITY.PRESENCE_TYPE.WAITING_ABSENCE_REQUEST";
      case PresenceType.UnverifiedSickLeave:
      case PresenceType.VerifiedSickLeave:
      case PresenceType.ChildSickLeave:
      case PresenceType.SpecialLeave:
        return "AVAILABILITY.PRESENCE_TYPE.APPROVED_ABSENCE";
      default:
        return "AVAILABILITY.PRESENCE_TYPE.UNKNOWN";
    }
  }

  presenceToHexColor(value: IPresenceModel) {
    switch (value.userState) {
      case 6: // Customer Project
        if (value.presence === PresenceType.HomeOffice)
          return "#90ee90"; // LightGreen;
        return "#008000"; // Green
      case 1: // Call
      case 3: // Intense Work
      case 4: // Free For Work
      case 5: // Internal Project
        return "#ffa500"; // Orange
      case 2: // AFK
        return "#000000"; // Black
      default:
        break;
    }

    switch (value.presence) {
      case PresenceType.Present:
        return "#008000"; // Green
      case PresenceType.BusinessTrip:
      case PresenceType.HomeOffice:
        return "#90ee90"; // LightGreen
      case PresenceType.BreakOrDone:
        return "#ffa500"; // Orange
      case PresenceType.Absent:
      case PresenceType.WaitingAbsence:
        return "#ff0000"; // Red
      case PresenceType.VerifiedSickLeave:
        return "#696969"; // DimGray
      case PresenceType.UnverifiedSickLeave:
        return "#a9a9a9"; // DarkGray
      default:
        return "#808080"; // Gray
    }
  }

  presenceToSortableNumber(value: IPresenceModel) {
    switch (value.userState) {
      case 6: // Customer Project // Green/LightGreen
        if (value.presence === PresenceType.HomeOffice)
          return 2;
        return 1;
      case 2:
        return 5; // AFK"; // Black
      case 1:
        return 15; // Call // Orange
      case 3:
        return 16; // Intense Work // Orange
      case 4: // Free For Work // Orange
        return 17;
      case 5: // Internal Project // Orange
        return 18;
      default:
        break;
    }

    switch (value.presence) {
      case PresenceType.Present: // Green
        return 0;
      case PresenceType.HomeOffice: // LightGreen
        return 3;
      case PresenceType.BusinessTrip: // LightGreen
        return 4;
      case PresenceType.Flextime: // Gray
        return 6;
      case PresenceType.RestDay: // Gray
        return 7;
      case PresenceType.Holiday: // Gray
        return 8;
      case PresenceType.Vacation: // Gray
        return 9;
      case PresenceType.ChildSickLeave: // Gray
        return 10;
      case PresenceType.SpecialLeave: // Gray
        return 11;
      case PresenceType.UnverifiedSickLeave: // DarkGray
        return 12;
      case PresenceType.VerifiedSickLeave: // DimGray
        return 13;
      case PresenceType.BreakOrDone: // Orange
        return 14;
      case PresenceType.WaitingAbsence: // Red
        return 19;
      case PresenceType.Absent: // Red
        return 20;
      default:
        return 21;
    }
  }
}
