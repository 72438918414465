import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth-guard';
import { AvailabilityComponent } from './availability.component';


const routes: Routes = [
  {
    path: 'availability',
    component: AvailabilityComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'AVAILABILITY.TITLE'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AvailabilityRoutingModule { }
