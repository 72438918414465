import { Injectable, Inject, Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthUserService {
  private user: AuthUser;
  private localStorageKey = 'user_info';

  constructor(@Inject(HttpClient) private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string, private router: Router, public dialog: MatDialog) { }

  isSignedIn() {
    return this.getUser() != null;
  }

  getUser() {
    if (this.user == null) {
      var userJson = localStorage.getItem(this.localStorageKey);
      if (userJson != null) {
        try {
          this.user = JSON.parse(userJson);
        } catch(e) {
          console.log(e);
          this.user = null;
        }
      }
    }
    return this.user;
  }

  signIn(token: string, provider: string) {
    return this.httpClient.post(this.baseUrl + '/api/account/login/' + provider, { Token: token }).toPromise().then(response => {
      var result = response as AuthUser[];
      if (result.length == 1) {
        this.signInUser(result[0]);
      } else {
        return this.selectUser(result).then(r => this.signInUser(r));
      }
    });
  }

  signInUser(user: AuthUser) {
    this.user = user;
    if(this.user) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.user));
    }
  }

  register(token: string, provider: string, username: string, organization: string, description: string) {
    let model = {
      Token: token,
      UserName: username,
      OrganizationName: organization,
      OrganizationDescription: description
    };
    
    return this.httpClient.post(this.baseUrl + '/api/account/organization/register/' + provider, model).toPromise().then(response => {
      this.user = response as AuthUser;
      if(this.user) {
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.user));
      }
    });
  }

  signOut() {
    this.user = null;
    localStorage.removeItem(this.localStorageKey);

    window.location.href = window.location.origin;
  }

  selectUser(users: AuthUser[]) {
    const dialogRef = this.dialog.open(OrganizationSelector, { disableClose: true, data: { users: users }});

    return dialogRef.afterClosed().toPromise().then(result => {
      return result.user;
    });
  }
}

export class AuthUser {
  userId: number;
  username: string;
  role: string;
  orgId: number;
  orgName: string;
  token: string;
}

@Component({
  selector: 'organization-selector',
  templateUrl: './organization-selector.html'
})
export class OrganizationSelector {
  user: AuthUser;
  users: AuthUser[];

  constructor(public dialogRef: MatDialogRef<OrganizationSelector>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.users = data.users.sort((a, b) => a.orgName.localeCompare(b.orgName));
    if(this.users.length > 0) {
      this.user = this.users[0];
    }
  }

  ok() {
    if (this.user) {
      this.dialogRef.close({user: this.user});
    }
  }
}
