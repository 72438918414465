import { Component, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IStandort, ApiClient } from '../../../services/api.client';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../services/comfirmation-dialog.service';
import { FilterService, FilterServiceModel } from 'src/app/services/filter-service';

@Component({
    selector: 'app-standort-list',
    templateUrl: './standort-list.component.html',
    styleUrls: ['./standort-list.component.css']
})
/** standort-list component*/
export class StandortListComponent implements OnDestroy {
  standortDS = new MatTableDataSource<IStandort>();
  displayedColumns: string[] = ['name', 'ssid', 'wlanId', 'gpsCoordinates', 'description', 'modified', 'actions'];
  filterModel: FilterServiceModel;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(filterService: FilterService, private apiClient: ApiClient, private router: Router, private confirmService: ConfirmationDialogService) {
    this.standortDS.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      const value: any = data[sortHeaderId === "modified" ? "modifiedDate" : sortHeaderId];
      return typeof value === "string" ? value.toLowerCase() : value;
    };
    this.filterModel = filterService.createFilter(this.standortDS, (p) => { }, () => { });
  }

  ngOnDestroy(): void {
    this.filterModel.destroy();
  }

  getStandorts() {
    this.apiClient.getStandortAll().subscribe(standorts => {
      standorts.forEach(standort => {
        Object.defineProperty(standort, "modifiedRealName", {
          get() {
            if (standort.modifiedUser == null)
              return "";
            return standort.modifiedUser.name;
          },
          enumerable: true,
          configurable: true
        });
        Object.defineProperty(standort, "gpsCoordinates", {
          get() {
            if (standort.coordinates[0] == null ||
              standort.coordinates[0].latitude == null || standort.coordinates[0].longitude == null ||
              standort.coordinates[0].latitude === 0 || standort.coordinates[0].longitude === 0)
              return null;
            return standort.coordinates[0].latitude.toString() + "," + standort.coordinates[0].longitude.toString();
          },
          enumerable: true,
          configurable: true
        });
      });
      this.standortDS.data = standorts;
    });
  }

  ngOnInit() {
    this.getStandorts();

    this.standortDS.paginator = this.paginator;
    this.standortDS.sort = this.sort;
    this.filterModel.loadFilterValuesAndApply();
  }

  applyFilter(filterValue: string) {
    this.filterModel.applyQuickFilter(filterValue);
  }

  delete(standort: IStandort) {
    this.confirmService.translateAndOpen('LOCATION.DELETE_CONFIRM', {name: standort.name}).subscribe(r => {
      if (r) {
        this.apiClient.deleteStandort(standort.id).subscribe(
          res => {
            this.standortDS.data = this.standortDS.data.filter((v, k) => {
              return v.id != standort.id;
            });
          });
      }
    });
  }

  openMap(gpsCoordinates: string) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: 'https://www.google.com/maps/place/' + gpsCoordinates,
    }).click();
  }
}
