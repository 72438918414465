import {Component, Input} from '@angular/core';
import {ApiClient, ChangeDiff} from '../../../services/api.client';
import {Location} from '@angular/common';

export interface CorrectionProjectDur {
  name: string,
  value: string,
  diff: string | null
}

@Component({
  selector: 'app-correction-details',
  templateUrl: './correction-details.component.html',
  styleUrls: ['./correction-details.component.scss']
})
export class CorrectionDetailsComponent {
  @Input() pause: ChangeDiff
  @Input() totalDuration: ChangeDiff
  @Input() durationByProject: CorrectionProjectDur[]
  @Input() user: any
  @Input() date: Date
  @Input() comment = ''

  readonly displayedColumns = ['project', 'hours'];

  constructor(
    private readonly apiClient: ApiClient,
    private readonly location: Location,
  ) {
  }

  approve() {
    this.apiClient.applyChanges(this.date, this.user.id).subscribe(() => this.location.back())
  }

  rejectRequest() {
    this.apiClient.rejectChanges(this.date, this.user.id).subscribe(() => this.location.back())
  }
}
