import { MatPaginatorIntl } from '@angular/material';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import {Injectable} from "@angular/core";

@Injectable()
export class TranslatedMatPaginatorIntl extends MatPaginatorIntl {

  private rangeLabelIntl: string;

  constructor(private translateService: TranslateService, private translateParser: TranslateParser) {
    super();
    this.setTranslations();
  }

  setTranslations() {
    this.translateService.get([
      'PAGINATOR.ITEMS_PER_PAGE',
      'PAGINATOR.NEXT',
      'PAGINATOR.LAST',
      'PAGINATOR.FIRST',
      'PAGINATOR.PREVIOUS',
      'PAGINATOR.RANGE'
    ]).subscribe(translation => {
        this.itemsPerPageLabel = translation['PAGINATOR.ITEMS_PER_PAGE'];
        this.nextPageLabel = translation['PAGINATOR.NEXT'];
        this.lastPageLabel = translation['PAGINATOR.LAST'];
        this.firstPageLabel = translation['PAGINATOR.FIRST'];
        this.previousPageLabel = translation['PAGINATOR.PREVIOUS'];
        this.rangeLabelIntl = translation['PAGINATOR.RANGE'];
        this.changes.next();
      });
  }

  getRangeLabel = (page, pageSize, length) => {
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return this.translateParser.interpolate(this.rangeLabelIntl, { start: startIndex, end: endIndex, total: length });
  };
}
