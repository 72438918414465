import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { of } from 'rxjs';
import { AuthGuard } from '../../auth-guard';
import { ApiClient, IUser, User } from '../../services/api.client';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserRegisterComponent } from './user-register/user-register.component';

@Injectable({ providedIn: 'root' })
export class UserResolve implements Resolve<IUser> {
  constructor(private service: ApiClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id) {
      return this.service.getUser(id);
    }
    return of(new User());
  }
}

const routes: Routes = [
  {
    path: 'user-list',
    component: UserListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'USER.TITLE'
    }
  },
  {
    path: 'user-detail/:id/edit',
    component: UserDetailComponent,
    resolve: {
      user: UserResolve
    },
    canActivate: [AuthGuard],
    data: {
      title: 'USER.TITLE'
    }
  },
  {
    path: 'user-detail/new',
    component: UserDetailComponent,
    resolve: {
      user: UserResolve
    },
    canActivate: [AuthGuard],
    data: {
      title: 'USER.TITLE'
    }
  },
  {
    path: 'user-register/:token/:provider',
    component: UserRegisterComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
