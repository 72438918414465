import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  ApiClient,
  WorktimeApprovalDailyDetail,
  WorktimeApprovalModel,
  WorktimeApprovalMonthlyDetails,
  WorktimeQuickEditModel, WorktimeType
} from 'src/app/services/api.client';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {LanguageConfigurator} from 'src/app/services/translation';
import {SettingsProviderService} from 'src/app/services/settings-provider-service';
import {NumberHelper} from 'src/app/services/number-helper';
import {AuthUser, AuthUserService} from 'src/app/services/auth-user/auth-user.service';
import {WorktimeDetailsQuickEditDialog} from '../worktime-details-quick-details-dialog/worktime-details-quick-details-dialog';
import {log} from 'util';


@Component({
  selector: 'worktime-details-dialog',
  templateUrl: 'worktime-details-dialog.component.html',
  styleUrls: ['../worktime-approval-list.component.scss']
})
export class WorktimeDetailsDialog {
  details: WorktimeApprovalMonthlyDetails;
  hoursAccountRange: number;
  displayedColumns = ['type', 'date', 'start', 'pauseDuration', 'end', 'duration', 'comments', 'actions'];
  updateOnClose: boolean;
  authUser: AuthUser;
  canEditSelf: boolean;

  constructor(
    public dialogRef: MatDialogRef<WorktimeDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: WorktimeApprovalModel,
    private apiClient: ApiClient,
    private router: Router,
    private languageConfigurator: LanguageConfigurator,
    private dialog: MatDialog,
    private settingsProvider: SettingsProviderService,
    private authUserService: AuthUserService
  ) {
    this.initDetails();
  }

  initDetails() {
    this.authUser = this.authUserService.getUser();
    this.apiClient.getUserCanEditSelf().subscribe(canEditSelf => {
      this.canEditSelf = canEditSelf;
    });
    this.apiClient.getApprovalMonthlyDetails(this.data.user.id, this.data.month, this.languageConfigurator.getCurrentLanguage()).subscribe(r => {
      this.details = r;
    });
    this.settingsProvider.getWorktimeSettings().subscribe(x => this.hoursAccountRange = x.hoursAccountWarningRange * 60);
  }

  onNoClick(): void {
    if (this.updateOnClose) {
      this.dialogRef.close({update: true});
    } else {
      this.dialogRef.close(true);
    }
  }

  openWorktimeDetaile(row: WorktimeApprovalDailyDetail) {
    const date = this.getDate(row.date).toDate().setHours(0, 0, 0, 0)
    const currentDate = new Date().setHours(0, 0,  0, 0)
    if (!row.hasCorrectionRequest && date > currentDate &&
      row.rowType !== WorktimeType.Holiday.toString() && row.rowType !== WorktimeType.SpecialLeave.toString() && row.rowType !== WorktimeType.ChildSickLeave.toString()) {
      return;
    }

    if (row.hasCorrectionRequest
      && (this.authUser.role == 'SUPER_ADMIN' || (this.authUser.role == 'WORKTIME_ADMIN' && this.authUser.userId !== this.data.user.id)))
      this.openCorrectionApproval(row);
    else {
      var navLink = 'worktime';

      if (this.authUser.role === 'USER' || this.authUser.role === 'STANDORT_ADMIN'
        || (this.authUser.role === 'WORKTIME_ADMIN' && this.data.user.id === this.authUser.userId && !this.canEditSelf)) {
        navLink = 'requests';
      }

      this.dialogRef.close(false);
      var mDate = this.getDate(row.date);

      this.router.navigate([navLink], {
        queryParams: {
          userId: this.data.user.id,
          date: mDate.format('YYYY-MM-DD')
        }
      });
    }
  }

  getDate(date) {
    var mDate = moment(this.data.month);
    return mDate.add(parseInt(date.slice(0, 2)) - 1, 'day');
  }

  getProjects() {
    var result = [];
    if (this.details && this.details.project) {
      this.details.project.forEach(p => {
        result.push(p.project);
      });
    }
    return result;
  }

  getProjectDetails() {
    var result = [];
    if (this.details && this.details.project) {
      var projectDetail = {};
      result.push(projectDetail);
      this.details.project.forEach(p => {
        projectDetail[p.project] = p.totalDuration;
      });
    }
    return result;
  }

  getMonthlyDetailsColumn() {
    return ['totalDuration', 'overtime', 'hoursAccount', 'vacations', 'holidays', 'sickDays'];
  }

  getMonthlyDetails() {
    if (this.details && this.details.total) {
      return [this.details.total];
    }
    return [];
  }

  getWarnings() {
    if (this.details && !this.details.isApproved && this.details.total.cantApproveErrors && this.details.total.cantApproveErrors.length > 0) {
      return 'ERROR.' + this.details.total.cantApproveErrors[0];
    }
    return null;
  }

  closeMonth() {
    this.apiClient.changeWorktimeApprovalStatus(this.data.user.id, this.data.month, true).subscribe(cr => {
      this.dialogRef.close({update: true});
    });
  }

  quickEdit(detail: WorktimeApprovalDailyDetail) {
    const monthAndYear = `${this.data.month.getFullYear()}-${this.data.month.getMonth() + 1}-${detail.date.slice(0, 2)}`;
    const dialogRef = this.dialog.open(
      WorktimeDetailsQuickEditDialog,
      {
        data: {
          type: detail.quickEditType,
          userId: this.data.user.id,
          start: detail.start,
          stop: detail.end,
          date: monthAndYear
        }
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.updateDetails !== true) {
        this.apiClient.worktimeQuickEdit(new WorktimeQuickEditModel({
          date: this.getDate(detail.date).toDate(),
          userId: this.data.user.id,
          start: result.start,
          stop: result.stop,
          pauseStart: result.pauseStart,
          pauseStop: result.pauseStop
        })).subscribe(() => {
          this.initDetails();
          this.updateOnClose = true;
        });
      }

      if(result && result.updateDetails === true) {
        this.initDetails();
        this.updateOnClose = true;
      }
    });
  }

  isHoursAccountOutRange() {
    var components = this.details.total.hoursAccount.replace('-', '').split(':');
    var hoursAccount = NumberHelper.Parse(components, 0) * 60 + NumberHelper.Parse(components, 1);

    return hoursAccount > this.hoursAccountRange;
  }

  checkCanEdit() {
    if (this.authUser.role === 'SUPER_ADMIN') {
      return true;
    }
    if (this.authUser.role === 'WORKTIME_ADMIN') {
      if (this.authUser.userId === this.data.user.id) {
        return this.canEditSelf;
      }
      return true;
    }
    return false;
  }

  openCorrectionApproval(element: WorktimeApprovalDailyDetail) {
    this.dialogRef.close(false)
    const mDate = this.getDate(element.date)
    this.router.navigate(['correctionApproval'], {
      queryParams: {
        userId: this.data.user.id,
        date: mDate.format('YYYY-MM-DD')
      }
    })
  }
}
