import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { of } from 'rxjs';
import { AuthGuard } from '../../auth-guard';
import { StandortDetailComponent } from './standort-detail/standort-detail.component';
import { StandortListComponent } from './standort-list/standort-list.component';
import { ApiClient, IStandort, Standort } from '../../services/api.client';

@Injectable({ providedIn: 'root' })
export class StandortResolve implements Resolve<IStandort> {
  constructor(private service: ApiClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id) {
      return this.service.getStandort(id);
    }
    return of(new Standort());
  }
}

const routes: Routes = [
  {
    path: 'location-list',
    component: StandortListComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'LOCATION.TITLE'
    }
  },
  {
    path: 'location-detail/:id/edit',
    component: StandortDetailComponent,
    resolve: {
      standort: StandortResolve
    },
    canActivate: [AuthGuard],
    data: {
      title: 'LOCATION.TITLE'
    }
  },
  {
    path: 'location-detail/new',
    component: StandortDetailComponent,
    resolve: {
      standort: StandortResolve
    },
    canActivate: [AuthGuard],
    data: {
      title: 'LOCATION.TITLE'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]})
export class StandortRoutingModule {
}
