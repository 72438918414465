import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiClient, ChangeDiff, WorktimeAggregateModel} from '../../../services/api.client';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {MatTableDataSource} from '@angular/material';
import {CorrectionProjectDur} from '../correction-details/correction-details.component';

@Component({
  selector: 'app-correction-view',
  templateUrl: './correction-view.component.html',
  styleUrls: ['./correction-view.component.scss']
})
export class CorrectionViewComponent implements OnInit, OnDestroy {
  existing = new MatTableDataSource<WorktimeAggregateModel>()
  requested = new MatTableDataSource<WorktimeAggregateModel>()
  date: Date
  displayedCols = ['typeOrLocation', 'start', 'stop', 'duration', 'project']
  pause: ChangeDiff
  totalDuration: ChangeDiff
  totalByProject: CorrectionProjectDur[]
  user: any
  comment: string

  private userId: number
  private subList: Subscription = new Subscription()
  constructor(
    private readonly apiClient: ApiClient,
    private readonly router: ActivatedRoute,
    private readonly location: Location,
  ) { }

  private extractParams() {
    const paramSub = this.router.queryParams.subscribe(params => {
      const {userId, date} = params
      this.userId = +userId
      this.date = new Date(date)
    })
    this.subList.add(paramSub)
  }

  private prepareProjectsData(durByProject: any) {
    this.totalByProject = []
    // tslint:disable-next-line:forin
    for (const key in durByProject) {
      const projDur: CorrectionProjectDur = {
        name: key.toString(),
        value: durByProject[key].value,
        diff: durByProject[key].diff
      }
      this.totalByProject.push(projDur)
    }
  }

  private init() {
    this.apiClient.getDetails(this.date, this.userId).subscribe(correctionDetails => {
      this.existing.data = correctionDetails.existing
      this.requested.data = correctionDetails.requested
      this.pause = correctionDetails.pause
      this.totalDuration = correctionDetails.totalDuration
      this.prepareProjectsData(correctionDetails.totalByProject)
      this.user = correctionDetails.requestedBy
      // @ts-ignore
      this.comment = correctionDetails.requestComment
    })
  }

  ngOnInit() {
    this.extractParams()
    this.init()
  }

  goBack() {
    this.location.back()
  }

  ngOnDestroy() {
    this.subList.unsubscribe()
  }
}
