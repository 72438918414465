import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  ApiClient,
  QuickEditType,
  WorktimeApprovalMonthlyDetails,
  WorktimeEditModel,
  WorktimeQuickEditModel,
  WorktimeType
} from '../../../../services/api.client';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NumberHelper} from '../../../../services/number-helper';
import {dateRangeValidator} from '../../../../shared';

@Component({
  selector: 'worktime-details-quick-details-dialog',
  templateUrl: './worktime-details-quick-details-dialog.html',
  styleUrls: ['../worktime-approval-list.component.scss']
})
export class WorktimeDetailsQuickEditDialog implements OnInit, OnDestroy {
  details: WorktimeApprovalMonthlyDetails;
  form: FormGroup;
  absenceForm: FormGroup;
  editType: QuickEditType;
  split = false;
  quickEditDate: Moment;
  isCorrection = true;
  typeControl: FormControl;
  private readonly subListDestroy: Subscription = new Subscription();

  absenceTypes = [
    WorktimeType.Correction,
    WorktimeType.SickLaeve,
    WorktimeType.ChildSickLeave,
    WorktimeType.SpecialLeave,
    WorktimeType.Holiday
  ];

  constructor(
    public dialogRef: MatDialogRef<WorktimeDetailsQuickEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiClient: ApiClient,
    private fb: FormBuilder
  ) {
    this.quickEditDate = moment(data.date);
    this.editType = data.type;
    this.typeControl = new FormControl(this.editType !== QuickEditType.AddAbsence ? WorktimeType.Correction : WorktimeType.Holiday);
    this.isCorrection = this.editType !== QuickEditType.AddAbsence;
    this.form = fb.group({});
    this.split = this.editType == QuickEditType.Split;
    this.form = fb.group({
      start: [{ value: this.getTime(data.start), disabled: this.editType == QuickEditType.Split }, [Validators.required]],
      stop: [{ value: this.getTime(data.stop), disabled: this.editType == QuickEditType.Split }, [Validators.required]],
      pauseStart: [''],
      pauseStop: ['']
    }, {validators: this.validatePauseBetweenStartStop()});

    this.absenceForm = fb.group({
      start: [this.quickEditDate, Validators.required],
      end: [this.quickEditDate, Validators.required]
    }, {
      validators: [dateRangeValidator('start', 'end', true)]
    });

  }

  ngOnInit() {
    this.subListDestroy.add(
      this.typeControl.valueChanges.subscribe(type => {
        this.isCorrection = type === WorktimeType.Correction;
        this.form.reset();
      })
    );

    if (this.editType === QuickEditType.AddAbsence) {
      this.absenceTypes.shift()
    }
  }

  get isCurrentFormInvalid() {
    return this.isCorrection ? this.form.invalid : this.absenceForm.invalid;
  }


  getTime(stringTime) {
    if(!stringTime) {
      return '';
    }
    let split = stringTime.split(':') as string[];
    return split.slice(0, 2).join(':');
  }

  validatePauseBetweenStartStop() {
    var getMinutes = this.getMinutes;
    return (formGroup: FormGroup): ValidationErrors => {
      let start = getMinutes(formGroup.controls.start.value);
      let stop = getMinutes(formGroup.controls.stop.value);
      let pauseStart = getMinutes(formGroup.controls.pauseStart.value);
      let pauseStop = getMinutes(formGroup.controls.pauseStop.value);

      if(start && stop) {
        let isValid = (pauseStart && pauseStop && start < pauseStart && pauseStart < pauseStop && pauseStop < stop) ||
          (this.editType == QuickEditType.Add && !pauseStart && !pauseStop && start < stop);
        if(!isValid) {
          return { pause_should_be_between_worktime: true };
        }
      }
      return null;
    };
  }

  getMinutes(timeString: String):Number {
    if(!timeString) {
      return null;
    }

    var split = timeString.split(':');
    return NumberHelper.Parse(split, 0) * 60 + NumberHelper.Parse(split, 1);
  }

  save() {
    if (this.isCorrection) {
      this.doCorrection();
    } else {
      this.doUpdateWorkTime();
    }

  }

  private doUpdateWorkTime() {
    this.apiClient.updateWorktime( 'new', new WorktimeEditModel({
      type: this.typeControl.value,
      projectId: 0,
      start: this.absenceForm.get('start').value,
      stop: this.absenceForm.get('end').value,
      userId: this.data.userId
    })).subscribe(() => this.dialogRef.close({ updateDetails: true }));
  }

  private doCorrection() {
    const result = new WorktimeQuickEditModel(this.form.getRawValue());
    result.start = this.replaceBadChars(result.start);
    result.stop = this.replaceBadChars(result.stop);
    result.pauseStart = this.replaceBadChars(result.pauseStart);
    result.pauseStop = this.replaceBadChars(result.pauseStop);
    this.dialogRef.close(result);
  }

  replaceBadChars(value: string) : string {
    return value.replace(/[^\x00-\x7F]/g, ""); //IE hack
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subListDestroy.unsubscribe();
  }
}
