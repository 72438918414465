import { Component, OnInit, Input, Inject } from "@angular/core";
import { ApiClient, HoursAccountAdjustmentModel } from "src/app/services/api.client";
import { MatTableDataSource } from "@angular/material/table";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConfirmationDialogService } from "src/app/services/comfirmation-dialog.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'user-hours-account-adjustment',
    templateUrl: './user-hours-account-adjustment.component.html',
    styleUrls: ['./user-hours-account-adjustment.component.css']
  })
  export class UserHoursAccountAdjustment implements OnInit {
    @Input() userId: number;

    adjustments = new MatTableDataSource<HoursAccountAdjustmentModel>();
    displayedColumns = ['date', 'adjustment', 'modified', 'comment', 'actions'];

    constructor(private apiClient: ApiClient, public dialog: MatDialog,
      private confirmService: ConfirmationDialogService, private translateService: TranslateService) { }
  
    ngOnInit() {
      this.loadAdjustments();
    }

    loadAdjustments() {
      this.apiClient.getAdjustmentsForUser(this.userId).subscribe(adjustments => {
        this.adjustments.data = adjustments;
      });
    }

    applyFilter(filterValue: string) {
      this.adjustments.filter = filterValue.trim().toLowerCase();
    }

    edit(item: HoursAccountAdjustmentModel) {
      const dialogRef = this.dialog.open(UserHoursAccountAdjustmentEditDialog, { data: item || {} });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.apiClient.saveAdjustmentsForUser(this.userId, result).subscribe(r => {
            this.loadAdjustments();
          });
        }
      });
    }

    delete(item: HoursAccountAdjustmentModel) {
      this.confirmService.translateAndOpen('USER.HOURS_ACCOUNT_ADJUSTMENTS.DELETE_CONFIRM').subscribe(r => {
        if(r) {
          this.apiClient.deleteAdjustmentsForUser(this.userId, item.id).subscribe(cr => {
            this.loadAdjustments();
          });
        }
      });
    }
  }

  @Component({
    selector: 'user-hours-account-adjustment-edit-dialog',
    templateUrl: 'user-hours-account-adjustment-edit.html',
    styleUrls: ['./user-hours-account-adjustment.component.css']
  })
  export class UserHoursAccountAdjustmentEditDialog {
    form: FormGroup;

    constructor(
      public dialogRef: MatDialogRef<UserHoursAccountAdjustmentEditDialog>,
      @Inject(MAT_DIALOG_DATA) public data: HoursAccountAdjustmentModel,
      private formbulider: FormBuilder
    ) {
      this.initForm();
      this.setFormValues(data);
    }

    initForm() {
      this.form = this.formbulider.group({
        date: ['', [Validators.required]],
        adjustment: ['', [Validators.required]],
        comment: ['', [Validators.required]]
      });
    }

    setFormValues(data: HoursAccountAdjustmentModel) {
      if (data.id > 0) {
        this.form.controls.date.setValue(data.date);
        this.form.controls.adjustment.setValue(data.adjustmentMinutes);
        this.form.controls.comment.setValue(data.comment);
      }
    }

    onNoClick(): void {
      this.dialogRef.close(false);
    }

    editMode() {
      return this.data.id > 0;
    }

    save() {
      if (this.form.valid) {
        this.dialogRef.close(new HoursAccountAdjustmentModel({
          id: this.data.id,
          date: this.form.controls.date.value,
          adjustmentMinutes: this.form.controls.adjustment.value,
          comment: this.form.controls.comment.value
        }));
      }
    }
  }
