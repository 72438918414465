import { Injectable } from "@angular/core";
import { ApiClient, SettingModel, GlobalSettings, WorktimeSettings, AdminSiteSettings, NotificationSettings } from "./api.client";
import { Observable, of } from "rxjs";
import { flatMap, map, share, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
  })
  export class SettingsProviderService {
      settings: SettingModel;

      constructor(private apiClient: ApiClient) {
      }

      public getSettings(): Observable<SettingModel> {
        if(this.settings == null) {
            return this.initSettings().pipe(flatMap(x => {
                return of(this.settings);
            }));
        }
        return of(this.settings);
      } 

      public getGlobalSettings(): Observable<GlobalSettings> {
        if (this.settings == null) {
          return this.initSettings().pipe(flatMap(x => {
            return of(this.settings.globalSettings);
          }));
        }
        return of(this.settings.globalSettings);
      }

      public getAdminSiteSettings(): Observable<AdminSiteSettings> {
        if (this.settings == null) {
          return this.initSettings().pipe(flatMap(x => {
            return of(this.settings.adminSiteSettings);
          }));
        }
        return of(this.settings.adminSiteSettings);
      }

      public getNotificationSettings(): Observable<NotificationSettings> {
        if (this.settings == null) {
          return this.initSettings().pipe(flatMap(x => {
            return of(this.settings.notificationSettings);
          }));
        }
        return of(this.settings.notificationSettings);
      }

      public getWorktimeSettings() : Observable<WorktimeSettings> {
        if(this.settings == null) {
            return this.initSettings().pipe(flatMap(x => {
                return of(this.settings.worktimeSettings);
            }));
        }
        return of(this.settings.worktimeSettings);
      }

      public resetSettings() : void {
          this.request = null;
          this.settings = null;
      }

      request: Observable<SettingModel>;
      private initSettings() : Observable<SettingModel> {
          if(this.request == null) {
            this.request = this.apiClient.getSettings().pipe(map(x => {
                this.request = null;
                this.settings = x;
                
                return x;
            }), share()).pipe(catchError((err, c) => {
              this.settings = new SettingModel({ globalSettings: new GlobalSettings({}), adminSiteSettings: new AdminSiteSettings({}), notificationSettings: new NotificationSettings({}), worktimeSettings: new WorktimeSettings({})});
                return of(this.settings);
            }));;
          }

          return this.request;
      }
  }
