import { Component, OnInit, Input, Inject } from "@angular/core";
import { ApiClient, WorkingHoursModel, HoursByDay, UserAvailableVacationsModel } from "src/app/services/api.client";
import { MatTableDataSource } from "@angular/material/table";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ConfirmationDialogService } from "src/app/services/comfirmation-dialog.service";
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'user-available-vacations',
    templateUrl: './user-available-vacations.component.html',
    styleUrls: ['./user-available-vacations.component.css']
  })
  export class UserAvailableVacations implements OnInit {
    @Input() userId: number;

    availableVacations = new MatTableDataSource<UserAvailableVacationsModel>();

    displayedColumns = ['effectiveDate', 'count', 'modified', 'comment', 'actions'];

    constructor(private apiClient: ApiClient, public dialog: MatDialog,
      private confirmService: ConfirmationDialogService) { }
  
    ngOnInit() {
      this.loadAvailableVacations();
    }

    loadAvailableVacations() {
      this.apiClient.getAvailableVacationsForUser(this.userId).subscribe(availableVacations => {
        this.availableVacations.data = availableVacations;
      });
    }

    applyFilter(filterValue: string) {
      this.availableVacations.filter = filterValue.trim().toLowerCase();
    }

    edit(item: WorkingHoursModel) {
      const dialogRef = this.dialog.open(UserAvailableVacationsEditDialog, { data: item || {} });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.apiClient.saveAvailableVacationsForUser(this.userId, result).subscribe(() => {
            this.loadAvailableVacations();
          });
        }
      });
    }

    delete(item: WorkingHoursModel) {
      this.confirmService.translateAndOpen('USER.AVAILABLE_VACATIONS.DELETE_CONFIRM', { effectiveDate: moment(item.effectiveDate).format('YYYY-MM-DD') }).subscribe(r => {
        if(r) {
          this.apiClient.deleteAvailableVacations(this.userId, item.id).subscribe(() => {
            this.loadAvailableVacations();
          });
        }
      });
    }
  }

  @Component({
    selector: 'user-available-vacations-edit-dialog',
    templateUrl: 'user-available-vacations-edit.html',
    styleUrls: ['./user-available-vacations.component.css']
  })
  export class UserAvailableVacationsEditDialog {
    form: FormGroup;

    constructor(
      public dialogRef: MatDialogRef<UserAvailableVacationsEditDialog>,
      @Inject(MAT_DIALOG_DATA) public data: UserAvailableVacationsModel,
      private formbulider: FormBuilder
    ) {
      this.initForm();
      this.setFormValues(data);
    }

    initForm() {
      this.form = this.formbulider.group({
        effectiveDate: ['', [Validators.required]],
        count: ['', [Validators.required]],
        comment: ['', [Validators.required]]
      });
    }

    setFormValues(data: UserAvailableVacationsModel) {
      if (data.id > 0) {
        this.form.controls.effectiveDate.setValue(data.effectiveDate);
        this.form.controls.count.setValue(data.count);
        this.form.controls.comment.setValue(data.comment);
      }
    }

    onNoClick(): void {
      this.dialogRef.close(false);
    }

    editMode() {
      return this.data.id > 0;
    }

    save() {
      if (this.form.valid) {
        this.dialogRef.close(new UserAvailableVacationsModel({
          id: this.data.id,
          effectiveDate: this.form.controls.effectiveDate.value,
          count: this.form.controls.count.value,
          comment: this.form.controls.comment.value
        }));
      }
    }
  }
