import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiClient, UserVacationModel, ChangeVacationStatusModel, VacationStatus, AdditionalAbsenceInformationDefinition, AdditionalAbsenceInformationType } from 'src/app/services/api.client';

@Component({
  selector: 'app-vacation-details',
  templateUrl: './vacation-details.component.html',
  styleUrls: ['./vacation-details.component.css']
})
export class VacationDetailsComponent implements OnInit {
  vacation: UserVacationModel;
  additionalAbsenceInformationDefinitions: AdditionalAbsenceInformationDefinition[];
  parsedAdditionalAbsenceInformation: string[][];
  statuses: string[];

  constructor(private route: ActivatedRoute, private apiClient: ApiClient) { }

  ngOnInit() {
    this.route.params.subscribe(p => {
      var id = p['id'];
      if (id) {
        this.apiClient.getVacation(id).subscribe(vacation => {
          this.vacation = vacation;
          this.apiClient.getAdditionalAbsenceInformationDefinitions(vacation.type).subscribe(definitions => {
            this.additionalAbsenceInformationDefinitions = definitions;
            this.parsedAdditionalAbsenceInformation = [];
            for (let key in vacation.additionalAbsenceInformation) {
              var parsedKey = 'ABSENCE.ADDITIONAL_INFORMATION.' + key;
              var parsedValue;
              var definition = definitions.find(d => d.name === key);
              if (definition) {
                switch (definition.type) {
                  case AdditionalAbsenceInformationType.Boolean:
                    parsedValue = (vacation.additionalAbsenceInformation[key] === 'True' ? 'GLOBAL.YES' : 'GLOBAL.NO');
                    break;
                  case AdditionalAbsenceInformationType.Enum:
                    parsedValue = 'ABSENCE.ADDITIONAL_INFORMATION.' + vacation.additionalAbsenceInformation[key];
                    break;
                  default: // Text or Date
                    if (vacation.additionalAbsenceInformation[key]) {
                      parsedValue = vacation.additionalAbsenceInformation[key];
                    } else {
                      parsedValue = 'ABSENCE.ADDITIONAL_INFORMATION.NO_ENTRY';
                    }
                    break;
                }
              } else { // absence must have been created with a definition that no longer exists
                switch (vacation.additionalAbsenceInformation[key]) {
                  case 'True':
                    parsedValue = 'GLOBAL.YES';
                    break;
                  case 'False':
                    parsedValue = 'GLOBAL.NO';
                    break;
                  default:
                    parsedValue = vacation.additionalAbsenceInformation[key];
                    break;
                }
              }
              this.parsedAdditionalAbsenceInformation.push([parsedKey, parsedValue]);
            }
          });
        })
      }
    });
  }

  changeStatus(model: ChangeVacationStatusModel) {
    this.apiClient.changeVacationStatus(this.vacation.id, model).subscribe(r => {
      window.history.back();
    });
  }

  approve() {
    var model = new ChangeVacationStatusModel({ status: VacationStatus.Approved, comment: this.vacation.responseComment });
    this.changeStatus(model);
  }

  reject() {
    var model = new ChangeVacationStatusModel({ status: VacationStatus.Rejected, comment: this.vacation.responseComment });
    this.changeStatus(model);
  }

  previousState() {
    window.history.back();
  }

  titleDetails() {
    var userName = (typeof this.vacation !== 'undefined') ? this.vacation.user.name : "";
    return userName;
  }
}
