import { Component } from '@angular/core';
import { AuthUserService } from '../services/auth-user/auth-user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  constructor(private authUserService: AuthUserService, private router: Router) { 
  }

  ngOnInit() {
    var redirects = [
      { route: '/worktimeApproval', roles: ['WORKTIME_ADMIN', 'SUPER_ADMIN'] },
      { route: '/correctionRequests', roles: ['STANDORT_ADMIN', 'USER']}
    ];

    var user = this.authUserService.getUser();
    var route = redirects.find(r => {
      return r.roles.indexOf(user.role) != -1;
    });

    if (route) {
      this.router.navigate([route.route]);
    }
  }
}
